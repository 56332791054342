<template>
  <div
    id="formDetailsModal"
    aria-hidden="true"
    class="modal fade"
    role="dialog"
    tabindex="-1"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <button
            aria-label="Close"
            class="close"
            data-dismiss="modal"
            type="button"
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <h2 class="modal-title text-center">
            Form Details
          </h2>
        </div>
        <div class="modal-body">
          <div
            class="row"
            style="margin-top: 25px"
          >
            <div class="col-xs-12">
              <div
                v-if="jobType !== 'Owner'"
                class="row"
              >
                <div class="form-group col-xs-offset-4 col-sm-offset-1 col-sm-3 col-xs-8">
                  <label class="form-details-label">Owner:</label>
                  <p class="text-muted">
                    {{ prettyPrint(formDetails.owner, 'User') }}
                  </p>
                </div>
                <div class="form-group col-sm-offset-1 col-sm-3 col-xs-offset-1 col-xs-4">
                  <label class="form-details-label">Supervisor:</label>
                  <p class="text-muted">
                    {{ prettyPrint(formDetails.supervisor, 'User') }}
                  </p>
                </div>
                <div class="form-group col-xs-offset-1 col-xs-6 col-sm-3">
                  <label class="form-details-label">Branch Manager:</label>
                  <p class="text-muted">
                    {{ prettyPrint(formDetails.branchManager, 'User') }}
                  </p>
                </div>
              </div>
              <div
                v-if="jobType === 'Owner'"
                class="row"
              >
                <div class="form-group col-sm-offset-1 col-sm-3">
                  <label class="has-float-label">
                    <label class="has-float-label">
                      <VueMultiselect
                        v-model="updateDetails.owner"
                        :allow-empty="false"
                        :class="{ 'placeholder-shown': (!updateDetails.owner || updateDetails.owner === '') }"
                        :custom-label="getUserName"
                        :options="_getUsers"
                        :placeholder="null"
                        :show-labels="false"
                        track-by="username"
                      />
                      <span class="float-label">{{ $Amplify.I18n.get('Owner') }}</span>
                    </label>
                  </label>
                </div>
                <div class="form-group col-sm-offset-1 col-sm-3 col-xs-offset-1 col-xs-4">
                  <label class="form-details-label">Supervisor:</label>
                  <p class="text-muted">
                    {{ prettyPrint(formDetails.supervisor, 'User') }}
                  </p>
                </div>
                <div class="form-group col-xs-offset-1 col-xs-6 col-sm-3">
                  <label class="form-details-label">Branch Manager:</label>
                  <p class="text-muted">
                    {{ prettyPrint(formDetails.branchManager, 'User') }}
                  </p>
                </div>
              </div>
              <div class="row">
                <div class="form-group col-xs-offset-4 col-sm-offset-5 col-xs-5">
                  <label class="form-details-label">Product:</label>
                  <p class="text-muted">
                    {{ prettyPrint(formDetails.product, 'Product') }}
                  </p>
                </div>
              </div>
              <div
                v-if="isYesBankForm"
                class="row"
              >
                <div class="form-group col-xs-offset-1 col-xs-3">
                  <label class="form-details-label">LG Code:</label>
                  <p class="text-muted">
                    {{ prettyPrint(formDetails.branchId, 'yesLGCode') }}
                  </p>
                </div>
                <div class="form-group col-xs-2">
                  <label class="form-details-label">LC Code:</label>
                  <p class="text-muted">
                    {{ prettyPrint(null, 'yesLCCode') }}
                  </p>
                </div>
                <div class="form-group col-xs-3">
                  <label class="form-details-label">Source Code:</label>
                  <p class="text-muted">
                    {{ prettyPrint(null, 'yesSourceCode') }}
                  </p>
                </div>
                <div class="form-group col-xs-3">
                  <label class="form-details-label">Branch Code:</label>
                  <p class="text-muted">
                    {{ prettyPrint(formDetails.zipcode, 'yesBranchCode') }}
                  </p>
                </div>
              </div>
              <div
                v-if="formDetails.verifiedIncomeProgram"
                class="row"
              >
                <div
                  class="form-group col-xs-offset-2 col-xs-5 col-sm-offset-4 col-sm-3"
                  style="border: 3px dashed darkred"
                >
                  <label class="form-details-label">Verified Income Program:</label>
                </div>
              </div>
              <div
                v-if="formDetails.appId && formDetails.appId !== ''"
                class="row"
              >
                <div class="form-group col-xs-offset-4 col-sm-offset-5 col-xs-5">
                  <label class="form-details-label">Application Number:</label>
                  <p class="text-muted">
                    {{ formDetails.appId }}
                  </p>
                </div>
              </div>
              <div
                v-if="reportOnly"
                class="row"
              >
                <div class="form-group col-md-offset-2 col-md-4 col-sm-offset-1 col-sm-5 col-xs-offset-1 col-xs-4">
                  <label class="form-details-label">Status:</label>
                  <p class="text-muted">
                    {{ prettyPrint(formDetails.status, 'Status') }}
                  </p>
                </div>
                <div
                  v-if="formDetails.statusJustifications && formDetails.statusJustifications.length > 0"
                  class="form-group col-sm-5 col-xs-offset-1 col-xs-6"
                >
                  <label class="form-details-label">Status Justification:</label>
                  <p class="text-muted">
                    {{ prettyPrint(formDetails.statusJustifications, 'Array') }}
                  </p>
                </div>
              </div>
              <div
                v-if="!reportOnly"
                class="row"
              >
                <div class="form-group col-sm-offset-1 col-sm-4">
                  <label class="has-float-label">
                    <VueMultiselect
                      v-model="updateDetails.status"
                      :allow-empty="false"
                      :class="{ 'placeholder-shown': (!updateDetails.status || updateDetails.status === '') }"
                      :options="_getStatuses"
                      :placeholder="null"
                      :show-labels="false"
                      label="name"
                      track-by="id"
                    />
                    <span class="float-label">{{ $Amplify.I18n.get('Status') }}</span>
                    <span
                      v-show="errors.has('updateDetails.status')"
                      class="help text-danger"
                    >{{ errors.first('updateDetails.status') }}</span>
                  </label>
                </div>
                <div
                  v-if="updateDetails.status && updateDetails.status.name === 'APPROVED'"
                  class="form-group col-sm-offset-1 col-sm-4"
                >
                  <div class="non-float-input">
                    <label>Verified Income Program: <i
                      v-if="!isHDFCBankForm"
                      aria-hidden="true"
                      class="fas fa-asterisk text-danger"
                      style="font-size: 7px; vertical-align: text-top;"
                    /></label>
                    <div class="radio-input-group">
                      <label class="radio-inline">
                        <input
                          v-model="updateDetails.verifiedIncomeProgram"
                          v-validate="{ required: !isHDFCBankForm }"
                          :class="errors.has('updateDetails.verifiedIncomeProgram') ? 'error' : ''"
                          :value="true"
                          data-vv-as="VIP"
                          name="updateDetails.verifiedIncomeProgram"
                          type="radio"
                        ><span>Yes</span>
                      </label>
                      <label class="radio-inline">
                        <input
                          v-model="updateDetails.verifiedIncomeProgram"
                          :value="false"
                          name="updateDetails.verifiedIncomeProgram"
                          type="radio"
                        ><span>No</span>
                      </label>
                    </div>
                  </div>
                  <span
                    v-show="errors.has('updateDetails.verifiedIncomeProgram')"
                    class="help text-danger"
                  >{{ errors.first('updateDetails.verifiedIncomeProgram') }}</span>
                </div>
                <div
                  v-if="updateDetails.status && updateDetails.status.name === 'ALREADY APPLIED'"
                  class="form-group col-sm-offset-1 col-sm-4"
                >
                  <label class="has-float-label">
                    <input
                      id="previouslyAppliedDatePicker"
                      v-model="updateDetails.previouslyAppliedDate"
                      v-validate="'required|date_format:dd-MM-yyyy'"
                      :class="errors.has('updateDetails.previouslyAppliedDate') ? 'error' : ''"
                      class="previously-date-picker date-picker"
                      data-vv-as="previously applied date"
                      name="updateDetails.previouslyAppliedDate"
                      placeholder=" "
                      type="text"
                    >
                    <span class="float-label">Previously Applied Date <i
                      aria-hidden="true"
                      class="fas fa-asterisk text-danger"
                      style="font-size: 7px; vertical-align: text-top;"
                    /></span>
                    <span
                      v-show="errors.has('updateDetails.previouslyAppliedDate')"
                      class="help text-danger"
                    >{{ errors.first('updateDetails.previouslyAppliedDate') }}</span>
                  </label>
                </div>
                <div
                  v-else-if="editJustification"
                  class="form-group col-sm-5"
                >
                  <label class="has-float-label">
                    <label class="has-float-label">
                      <VueMultiselect
                        v-model="updateDetails.statusJustifications"
                        :allow-empty="true"
                        :class="{ 'placeholder-shown': (!updateDetails.statusJustifications || updateDetails.statusJustifications.length < 1) }"
                        :multiple="true"
                        :options="_getStatusJustifications"
                        :placeholder="null"
                        :show-labels="false"
                      />
                      <span class="float-label">Status Reason</span>
                    </label>
                  </label>
                </div>
              </div>
              <div v-if="jobType === 'MIS'">
                <div
                  v-if="!updateDetails.bankReceivedDate || updateDetails.bankReceivedDate === ''"
                  class="form-group col-sm-offset-1 col-sm-4"
                >
                  <label class="has-float-label">
                    <input
                      id="bankReceivedDatePicker"
                      v-model="updateDetails.bankReceivedDate"
                      v-validate="'date_format:dd-MM-yyyy'"
                      :class="errors.has('updateDetails.bankReceivedDate') ? 'error' : ''"
                      class="received-date-picker date-picker"
                      data-vv-as="bank received date"
                      name="updateDetails.bankReceivedDate"
                      placeholder=" "
                      type="text"
                    >
                    <span class="float-label">Bank Received Date <i
                      aria-hidden="true"
                      class="fas fa-asterisk text-danger"
                      style="font-size: 7px; vertical-align: text-top;"
                    /></span>
                    <span
                      v-show="errors.has('updateDetails.bankReceivedDate')"
                      class="help text-danger"
                    >{{ errors.first('updateDetails.bankReceivedDate') }}</span>
                  </label>
                </div>
                <div
                  v-if="!updateDetails.bankResultDate || updateDetails.bankResultDate === ''"
                  class="form-group col-sm-offset-1 col-sm-4"
                >
                  <label class="has-float-label">
                    <input
                      id="bankResultDatePicker"
                      v-model="updateDetails.bankResultDate"
                      v-validate="'date_format:dd-MM-yyyy'"
                      :class="errors.has('updateDetails.bankResultDate') ? 'error' : ''"
                      class="result-date-picker date-picker"
                      data-vv-as="bank result date"
                      name="updateDetails.bankResultDate"
                      placeholder=" "
                      type="text"
                    >
                    <span class="float-label">Bank Result Date <i
                      aria-hidden="true"
                      class="fas fa-asterisk text-danger"
                      style="font-size: 7px; vertical-align: text-top;"
                    /></span>
                    <span
                      v-show="errors.has('updateDetails.bankResultDate')"
                      class="help text-danger"
                    >{{ errors.first('updateDetails.bankResultDate') }}</span>
                  </label>
                </div>
              </div>
              <div
                v-if="!reportOnly && updateDetails.status &&
                  (updateDetails.status.name === 'ORDER CLOSED'
                    || updateDetails.status.name === 'DOCUMENTS PRINTED'
                    || updateDetails.status.name === 'OUTCALL - DONE'
                    || updateDetails.status.name === 'FA ACCEPTED')
                  && (jobType === 'Documents' || jobType === 'FormAccept')"
                class="row"
              >
                <div class="form-group col-sm-offset-2 col-sm-4">
                  <label class="has-float-label">
                    <input
                      id="appId"
                      ref="updateDetails.appId"
                      v-model="updateDetails.appId"
                      v-validate="'required'"
                      :class="errors.has('updateDetails.appId') ? 'error' : ''"
                      data-vv-as="app #"
                      name="updateDetails.appId"
                      placeholder=" "
                      type="text"
                    >
                    <span class="float-label">Application Number</span>
                    <span
                      v-show="errors.has('updateDetails.appId')"
                      class="help text-danger"
                    >{{ errors.first('updateDetails.appId') }}</span>
                  </label>
                </div>
                <div class="form-group col-sm-offset-1 col-sm-4">
                  <label class="has-float-label">
                    <input
                      id="appIdConfirmation"
                      v-model="appIdConfirmation"
                      v-validate="'required|confirmed:updateDetails.appId'"
                      :class="errors.has('appIdConfirmation') ? 'error' : ''"
                      data-vv-as="app #"
                      name="appIdConfirmation"
                      placeholder=" "
                      type="text"
                      @keypress.stop.prevent="formatAppIdConfirmation"
                      @change.stop.prevent="formatAppIdConfirmationMobile"
                      @paste.stop.prevent="''"
                    >
                    <span class="float-label">Confirm App #</span>
                    <span
                      v-show="errors.has('appIdConfirmation')"
                      class="help text-danger"
                    >{{ errors.first('appIdConfirmation') }}</span>
                  </label>
                </div>
              </div>
              <div
                v-if="!reportOnly && (updateDetails.status && (updateDetails.status.name === 'APPROVED' || updateDetails.status.name === 'ORDER CLOSED' || updateDetails.status.name === 'LINK SENT') && jobType === 'BackOffice')"
                class="row"
              >
                <div class="form-group col-sm-offset-1 col-sm-5">
                  <label class="has-float-label">
                    <input
                      v-model="updateDetails.referenceNumber"
                      v-validate="{ required: !isHDFCBankForm && updateDetails.status.name !== 'LINK SENT' }"
                      :class="errors.has('updateDetails.referenceNumber') ? 'error' : ''"
                      data-vv-as="reference number"
                      name="updateDetails.referenceNumber"
                      placeholder=" "
                      type="text"
                    >
                    <span class="float-label">Reference Number</span>
                    <span
                      v-show="errors.has('updateDetails.referenceNumber')"
                      class="help text-danger"
                    >{{ errors.first('updateDetails.referenceNumber') }}</span>
                  </label>
                </div>
              </div>
              <div
                v-if="formDetails.previouslyAppliedDate && formDetails.previouslyAppliedDate !== ''"
                class="row"
              >
                <div class="form-group col-sm-offset-1 col-sm-3">
                  <label class="form-details-label">Submission Date:</label>
                  <p class="text-muted">
                    {{ prettyPrint(formDetails.createdAt, 'Date') }}
                  </p>
                </div>
                <div class="form-group col-sm-offset-1 col-sm-3">
                  <label class="form-details-label">
                    {{ isYesBankForm ? 'Link Send ' : 'Bank Verification ' }}Date:
                  </label>
                  <p class="text-muted">
                    {{ prettyPrint(formDetails.bankVerificationDate, 'Date') }}
                  </p>
                </div>
                <div class="form-group col-sm-3">
                  <label class="form-details-label">Previously Applied Date:</label>
                  <p class="text-muted">
                    {{ prettyPrint(formDetails.previouslyAppliedDate, 'Date') }}
                  </p>
                </div>
              </div>
              <div
                v-else
                class="row"
              >
                <div class="form-group col-md-offset-2 col-md-4 col-sm-offset-1 col-sm-5 col-xs-offset-1 col-xs-4">
                  <label class="form-details-label">Submission Date:</label>
                  <p class="text-muted">
                    {{ prettyPrint(formDetails.createdAt, 'Date') }}
                  </p>
                </div>
                <div class="form-group col-sm-5 col-xs-offset-1 col-xs-6">
                  <label class="form-details-label">
                    {{ isYesBankForm ? 'Link Send ' : 'Bank Verification ' }}Date:
                  </label>
                  <p class="text-muted">
                    {{ prettyPrint(formDetails.bankVerificationDate, 'Date') }}
                  </p>
                </div>
              </div>
              <div
                v-if="formDetails.bankReceivedDate && formDetails.bankReceivedDate !== ''"
                class="row"
              >
                <div class="form-group col-md-offset-2 col-md-4 col-sm-offset-1 col-sm-5 col-xs-offset-1 col-xs-4">
                  <label class="form-details-label">Bank Received Date:</label>
                  <p class="text-muted">
                    {{ prettyPrint(formDetails.bankReceivedDate, 'Date') }}
                  </p>
                </div>
                <div class="form-group col-sm-5 col-xs-offset-1 col-xs-6">
                  <label class="form-details-label">Bank Result Date:</label>
                  <p class="text-muted">
                    {{ prettyPrint(formDetails.bankResultDate, 'Date') }}
                  </p>
                </div>
              </div>
              <div class="row">
                <div class="form-group col-sm-offset-1 col-sm-2 col-xs-offset-1 col-xs-3">
                  <label class="form-details-label">Product Type:</label>
                  <p class="text-muted">
                    {{ formDetails.offerType }}
                  </p>
                </div>
                <template v-if="isYesBankForm">
                  <div class="form-group col-sm-2 col-xs-3">
                    <label class="form-details-label">Card Type:</label>
                    <p class="text-muted">
                      {{ formDetails.cardType }}
                    </p>
                  </div>
                </template>
                <div
                  v-if="!reportOnly"
                  class="form-group col-sm-offset-1 col-sm-5 col-xs-5"
                >
                  <label class="has-float-label">
                    <input
                      v-model="updateDetails.remarks"
                      placeholder=" "
                      type="text"
                    >
                    <span class="float-label">Remarks</span>
                  </label>
                </div>
                <div
                  v-else
                  class="form-group col-sm-offset-1 col-sm-5 col-xs-5"
                >
                  <label class="form-details-label">Remarks:</label>
                  <p class="text-muted">
                    {{ formDetails.remarks }}
                  </p>
                </div>
              </div>
              <div
                v-if="formDetails.referenceNumber && formDetails.referenceNumber !== ''"
                class="row"
              >
                <div class="form-group col-xs-offset-1 col-sm-5">
                  <label
                    class="form-details-label"
                    for="referenceNumber"
                  >Reference Number:</label>
                  <p
                    id="referenceNumber"
                    class="text-muted"
                  >
                    {{ formDetails.referenceNumber }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="formDocuments && formDocuments.length > 0"
            class="row m-b-lg"
          >
            <div class="col-xs-12">
              <div class="row">
                <div class="col-xs-12 text-center">
                  <h4>Documents</h4>
                </div>
              </div>
              <ul class="list-unstyled text-center">
                <!-- eslint-disable vue/no-v-html -->
                <li
                  v-for="(document, index) in formDocuments"
                  :key="index"
                  v-html="document"
                />
                <!--eslint-enable-->
              </ul>
            </div>
          </div>
          <div v-if="isEditable">
            <div class="row m-b-lg">
              <div class="col-xs-12">
                <div class="row">
                  <div class="col-xs-12 text-center">
                    <h4>Personal Info</h4>
                  </div>
                </div>
                <div
                  v-if="jobType === 'OutCaller'"
                  class="row"
                >
                  <div class="form-group col-sm-offset-1 col-sm-5">
                    <label class="has-float-label">
                      <input
                        v-model="updateDetails.nameDesired"
                        v-validate="'required'"
                        :class="errors.has('updateDetails.nameDesired') ? 'error' : ''"
                        data-vv-as="desired name"
                        name="updateDetails.nameDesired"
                        placeholder=" "
                        type="text"
                      >
                      <span class="float-label">Name Desired On Card <i
                        aria-hidden="true"
                        class="fas fa-asterisk text-danger"
                        style="font-size: 7px; vertical-align: text-top;"
                      /></span>
                      <span
                        v-show="errors.has('updateDetails.nameDesired')"
                        class="help text-danger"
                      >{{ errors.first('updateDetails.nameDesired') }}</span>
                    </label>
                  </div>
                  <div class="form-group col-sm-offset-1 col-sm-4">
                    <label class="has-float-label">
                      <input
                        v-model="updateDetails.motherName"
                        v-validate="'required'"
                        :class="errors.has('updateDetails.motherName') ? 'error' : ''"
                        data-vv-as="mother name"
                        name="updateDetails.motherName"
                        placeholder=" "
                        type="text"
                      >
                      <span class="float-label">Mother Name <i
                        aria-hidden="true"
                        class="fas fa-asterisk text-danger"
                        style="font-size: 7px; vertical-align: text-top;"
                      /></span>
                      <span
                        v-show="errors.has('updateDetails.motherName')"
                        class="help text-danger"
                      >{{ errors.first('updateDetails.motherName') }}</span>
                    </label>
                  </div>
                </div>
                <div class="row">
                  <div class="form-group col-md-offset-1 col-md-5">
                    <div class="non-float-input">
                      <label>Title: <i
                        aria-hidden="true"
                        class="fas fa-asterisk text-danger"
                        style="font-size: 7px; vertical-align: text-top;"
                      /></label>
                      <div class="radio-input-group">
                        <label class="radio-inline">
                          <input
                            v-model="updateDetails.title"
                            v-validate="'required'"
                            :class="errors.has('updateDetails.title') ? 'error' : ''"
                            data-vv-as="title"
                            name="updateDetails.title"
                            type="radio"
                            value="MR."
                          ><span>Mr.</span>
                        </label>
                        <label class="radio-inline">
                          <input
                            v-model="updateDetails.title"
                            name="updateDetails.title"
                            type="radio"
                            value="MRS."
                          ><span>Mrs.</span>
                        </label>
                        <label class="radio-inline">
                          <input
                            v-model="updateDetails.title"
                            name="updateDetails.title"
                            type="radio"
                            value="MS."
                          ><span>Ms.</span>
                        </label>
                        <label class="radio-inline">
                          <input
                            v-model="updateDetails.title"
                            name="updateDetails.title"
                            type="radio"
                            value="DR."
                          ><span>Dr.</span>
                        </label>
                      </div>
                    </div>
                    <span
                      v-show="errors.has('updateDetails.title')"
                      class="help text-danger"
                    >{{ errors.first('updateDetails.title') }}</span>
                  </div>
                  <div class="form-group col-md-offset-1 col-md-4">
                    <label class="has-float-label">
                      <input
                        v-model="updateDetails.firstName"
                        v-validate="'required'"
                        :class="errors.has('updateDetails.firstName') ? 'error' : ''"
                        data-vv-as="first name"
                        name="updateDetails.firstName"
                        placeholder=" "
                        type="text"
                      >
                      <span class="float-label">First Name <i
                        aria-hidden="true"
                        class="fas fa-asterisk text-danger"
                        style="font-size: 7px; vertical-align: text-top;"
                      /></span>
                      <span
                        v-show="errors.has('updateDetails.firstName')"
                        class="help text-danger"
                      >{{ errors.first('updateDetails.firstName') }}</span>
                    </label>
                  </div>
                  <div class="form-group col-md-offset-1 col-md-5">
                    <label class="has-float-label">
                      <input
                        v-model="updateDetails.fatherName"
                        v-validate="{ required: !isYesBankForm }"
                        :class="errors.has('updateDetails.fatherName') ? 'error' : ''"
                        data-vv-as="father name"
                        name="updateDetails.fatherName"
                        placeholder=" "
                        type="text"
                      >
                      <span class="float-label">Father Name <i
                        v-if="!isYesBankForm"
                        aria-hidden="true"
                        class="fas fa-asterisk text-danger"
                        style="font-size: 7px; vertical-align: text-top;"
                      /></span>
                      <span
                        v-show="errors.has('updateDetails.fatherName')"
                        class="help text-danger"
                      >{{ errors.first('updateDetails.fatherName') }}</span>
                    </label>
                  </div>
                  <div class="form-group col-md-offset-1 col-md-4">
                    <label class="has-float-label">
                      <input
                        v-model="updateDetails.lastName"
                        v-validate="'required'"
                        :class="errors.has('updateDetails.lastName') ? 'error' : ''"
                        data-vv-as="last name"
                        name="updateDetails.lastName"
                        placeholder=" "
                        type="text"
                      >
                      <span class="float-label">Last Name <i
                        aria-hidden="true"
                        class="fas fa-asterisk text-danger"
                        style="font-size: 7px; vertical-align: text-top;"
                      /></span>
                      <span
                        v-show="errors.has('updateDetails.lastName')"
                        class="help text-danger"
                      >{{ errors.first('updateDetails.lastName') }}</span>
                    </label>
                  </div>
                </div>
                <div class="row">
                  <div class="form-group col-md-offset-1 col-md-5">
                    <label class="has-float-label">
                      <input
                        v-model="updateDetails.pancard"
                        v-validate="{ required: true, regex: /^[aA-zZ]{5}[\d]{4}[aA-zZ]$/ }"
                        :class="errors.has('updateDetails.pancard') ? 'error' : ''"
                        data-vv-as="pancard"
                        name="updateDetails.pancard"
                        placeholder=" "
                        type="text"
                      >
                      <span class="float-label">Pancard <i
                        aria-hidden="true"
                        class="fas fa-asterisk text-danger"
                        style="font-size: 7px; vertical-align: text-top;"
                      /></span>
                      <span
                        v-show="errors.has('updateDetails.pancard')"
                        class="help text-danger"
                      >{{ errors.first('updateDetails.pancard') }}</span>
                    </label>
                  </div>
                  <div class="form-group col-md-offset-1 col-md-3 col-xs-9">
                    <label class="has-float-label">
                      <input
                        v-model="updateDetails.dateOfBirth"
                        v-validate="'required|date_format:dd-MM-yyyy|age-check'"
                        :class="errors.has('updateDetails.dateOfBirth') ? 'error' : ''"
                        class="dob-picker date-picker"
                        data-vv-as="DOB"
                        name="updateDetails.dateOfBirth"
                        placeholder=" "
                        type="text"
                      >
                      <span class="float-label">Date Of Birth <i
                        aria-hidden="true"
                        class="fas fa-asterisk text-danger"
                        style="font-size: 7px; vertical-align: text-top;"
                      /></span>
                      <span
                        v-show="errors.has('updateDetails.dateOfBirth')"
                        class="help text-danger"
                      >{{ errors.first('updateDetails.dateOfBirth') }}</span>
                    </label>
                  </div>
                  <div class="form-group col-md-1 col-xs-3">
                    <label
                      class="form-details-label"
                      for="calcAge"
                    >Age:</label>
                    <p
                      id="calcAge"
                      class="text-muted"
                    >
                      {{ calcAge }}
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div class="form-group col-md-offset-1 col-md-5">
                    <div class="non-float-input">
                      <label>Gender: <i
                        aria-hidden="true"
                        class="fas fa-asterisk text-danger"
                        style="font-size: 7px; vertical-align: text-top;"
                      /></label>
                      <div class="radio-input-group">
                        <div class="radio-inline">
                          <label>
                            <input
                              v-model="updateDetails.gender"
                              v-validate="'required'"
                              :class="errors.has('updateDetails.gender') ? 'error' : ''"
                              data-vv-as="gender"
                              name="updateDetails.gender"
                              type="radio"
                              value="MALE"
                            >Male
                          </label>
                        </div>
                        <div class="radio-inline">
                          <label>
                            <input
                              v-model="updateDetails.gender"
                              name="updateDetails.gender"
                              type="radio"
                              value="FEMALE"
                            >Female
                          </label>
                        </div>
                      </div>
                    </div>
                    <span
                      v-show="errors.has('updateDetails.gender')"
                      class="help text-danger"
                    >{{ errors.first('updateDetails.gender') }}</span>
                  </div>
                  <div
                    v-if="jobType === 'OutCaller'"
                    class="form-group col-md-offset-1 col-md-4"
                  >
                    <div class="non-float-input">
                      <label>Marital Status: <i
                        aria-hidden="true"
                        class="fas fa-asterisk text-danger"
                        style="font-size: 7px; vertical-align: text-top;"
                      /></label>
                      <div class="radio-input-group">
                        <label class="radio-inline">
                          <input
                            v-model="updateDetails.maritalStatus"
                            v-validate="'required'"
                            :class="errors.has('updateDetails.maritalStatus') ? 'error' : ''"
                            data-vv-as="marital status"
                            name="updateDetails.maritalStatus"
                            type="radio"
                            value="SINGLE"
                          ><span>Single</span>
                        </label>
                        <label class="radio-inline">
                          <input
                            v-model="updateDetails.maritalStatus"
                            name="updateDetails.maritalStatus"
                            type="radio"
                            value="MARRIED"
                          ><span>Married</span>
                        </label>
                      </div>
                    </div>
                    <span
                      v-show="errors.has('updateDetails.maritalStatus')"
                      class="help text-danger"
                    >{{ errors.first('updateDetails.maritalStatus') }}</span>
                  </div>
                </div>
                <div class="row">
                  <div class="form-group col-md-offset-1 col-md-5">
                    <label class="has-float-label">
                      <input
                        v-model="updateDetails.mobileNumber"
                        v-validate="'required|digits:10'"
                        :class="errors.has('updateDetails.mobileNumber') ? 'error' : ''"
                        data-vv-as="mobile number"
                        name="updateDetails.mobileNumber"
                        placeholder=" "
                        type="text"
                      >
                      <span class="float-label">Mobile Number (For AIP) <i
                        aria-hidden="true"
                        class="fas fa-asterisk text-danger"
                        style="font-size: 7px; vertical-align: text-top;"
                      /></span>
                      <span
                        v-show="errors.has('updateDetails.mobileNumber')"
                        class="help text-danger"
                      >{{ errors.first('updateDetails.mobileNumber') }}</span>
                    </label>
                  </div>
                  <div class="form-group col-md-offset-1 col-md-4">
                    <label class="has-float-label">
                      <input
                        v-model="updateDetails.mobileNumberAlternate"
                        v-validate="'digits:10'"
                        :class="errors.has('updateDetails.mobileNumberAlternate') ? 'error' : ''"
                        data-vv-as="alternate number"
                        name="updateDetails.mobileNumberAlternate"
                        placeholder=" "
                        type="text"
                      >
                      <span class="float-label">Alternate Number</span>
                      <span
                        v-show="errors.has('updateDetails.mobileNumberAlternate')"
                        class="help text-danger"
                      >{{ errors.first('updateDetails.mobileNumberAlternate') }}</span>
                    </label>
                  </div>
                  <div class="form-group col-md-offset-1 col-md-5">
                    <label class="has-float-label">
                      <input
                        v-model="updateDetails.email"
                        v-validate="'required|email'"
                        :class="errors.has('updateDetails.email') ? 'error' : ''"
                        data-vv-as="email"
                        name="updateDetails.email"
                        placeholder=" "
                        type="text"
                      >
                      <span class="float-label">Personal Email ID <i
                        aria-hidden="true"
                        class="fas fa-asterisk text-danger"
                        style="font-size: 7px; vertical-align: text-top;"
                      /></span>
                      <span
                        v-show="errors.has('updateDetails.email')"
                        class="help text-danger"
                      >{{ errors.first('updateDetails.email') }}</span>
                    </label>
                  </div>
                  <div
                    v-if="jobType === 'OutCaller'"
                    class="form-group col-md-offset-1 col-md-4"
                  >
                    <label class="has-float-label">
                      <label class="has-float-label">
                        <VueMultiselect
                          v-model="updateDetails.education"
                          :allow-empty="false"
                          :class="{ 'placeholder-shown': (!updateDetails.education || updateDetails.education === '') }"
                          :options="getEducationOptions"
                          :placeholder="null"
                          :show-labels="false"
                        />
                        <span class="float-label">Education <i
                          aria-hidden="true"
                          class="fas fa-asterisk text-danger"
                          style="font-size: 7px; vertical-align: text-top;"
                        /></span>
                      </label>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="row m-b-lg">
              <div class="col-xs-12">
                <div class="row">
                  <div class="col-xs-12 text-center">
                    <h4>Resident Address Details (As Per Document Only)</h4>
                  </div>
                </div>
                <div class="row">
                  <div class="form-group col-md-offset-1 col-md-5">
                    <label class="has-float-label">
                      <input
                        v-model="updateDetails.addressLine1"
                        v-validate="{ required: !isHDFCBankForm, min: 3, max: 39, regex: /^[\w\d\s]+$/ }"
                        :class="errors.has('updateDetails.addressLine1') ? 'error' : ''"
                        data-vv-as="address line 1"
                        name="updateDetails.addressLine1"
                        placeholder=" "
                        type="text"
                      >
                      <span class="float-label">Flat / Room No., Building No., Wing / Floor, Building Name <i
                        v-if="!isHDFCBankForm"
                        aria-hidden="true"
                        class="fas fa-asterisk text-danger"
                        style="font-size: 7px; vertical-align: text-top;"
                      /></span>
                      <span
                        v-show="errors.has('updateDetails.addressLine1')"
                        class="help text-danger"
                      >{{ errors.first('updateDetails.addressLine1') }}</span>
                    </label>
                  </div>
                  <div class="form-group col-md-offset-1 col-md-4">
                    <label class="has-float-label">
                      <input
                        v-model="updateDetails.addressLine2"
                        v-validate="{ required: !isHDFCBankForm, regex: /^[\w\d\s]+$/ }"
                        :class="errors.has('updateDetails.addressLine2') ? 'error' : ''"
                        data-vv-as="address line 2"
                        name="updateDetails.addressLine2"
                        placeholder=" "
                        type="text"
                      >
                      <span class="float-label">Street Name, Sector, Plot No. <i
                        v-if="!isHDFCBankForm"
                        aria-hidden="true"
                        class="fas fa-asterisk text-danger"
                        style="font-size: 7px; vertical-align: text-top;"
                      /></span>
                      <span
                        v-show="errors.has('updateDetails.addressLine2')"
                        class="help text-danger"
                      >{{ errors.first('updateDetails.addressLine2') }}</span>
                    </label>
                  </div>
                </div>
                <div class="row">
                  <div class="form-group col-md-offset-1 col-md-5">
                    <label class="has-float-label">
                      <input
                        v-model="updateDetails.landmark"
                        v-validate="{ required: !isHDFCBankForm, regex: /^[\w\d\s]+$/ }"
                        :class="errors.has('updateDetails.landmark') ? 'error' : ''"
                        data-vv-as="landmark"
                        name="updateDetails.landmark"
                        placeholder=" "
                        type="text"
                      >
                      <span class="float-label">Landmark <i
                        v-if="!isHDFCBankForm"
                        aria-hidden="true"
                        class="fas fa-asterisk text-danger"
                        style="font-size: 7px; vertical-align: text-top;"
                      /></span>
                      <span
                        v-show="errors.has('updateDetails.landmark')"
                        class="help text-danger"
                      >{{ errors.first('updateDetails.landmark') }}</span>
                    </label>
                  </div>
                  <div class="form-group col-md-offset-1 col-md-4">
                    <label class="has-float-label">
                      <input
                        v-model="updateDetails.station"
                        v-validate="{ required: !isHDFCBankForm, regex: /^[\w\d\s]+$/ }"
                        :class="errors.has('updateDetails.station') ? 'error' : ''"
                        data-vv-as="station"
                        name="updateDetails.station"
                        placeholder=" "
                        type="text"
                      >
                      <span class="float-label">Station <i
                        v-if="!isHDFCBankForm"
                        aria-hidden="true"
                        class="fas fa-asterisk text-danger"
                        style="font-size: 7px; vertical-align: text-top;"
                      /></span>
                      <span
                        v-show="errors.has('updateDetails.station')"
                        class="help text-danger"
                      >{{ errors.first('updateDetails.station') }}</span>
                    </label>
                  </div>
                  <div class="form-group col-md-offset-1 col-md-5">
                    <label class="has-float-label">
                      <input
                        v-model="updateDetails.city"
                        v-validate="{ required: true, regex: /^[\w\d\s]+$/ }"
                        :class="errors.has('updateDetails.city') ? 'error' : ''"
                        data-vv-as="city"
                        name="updateDetails.city"
                        placeholder=" "
                        type="text"
                      >
                      <span class="float-label">City <i
                        aria-hidden="true"
                        class="fas fa-asterisk text-danger"
                        style="font-size: 7px; vertical-align: text-top;"
                      /></span>
                      <span
                        v-show="errors.has('updateDetails.city')"
                        class="help text-danger"
                      >{{ errors.first('updateDetails.city') }}</span>
                    </label>
                  </div>
                  <div class="form-group col-md-offset-1 col-md-4">
                    <label class="has-float-label">
                      <input
                        v-model="updateDetails.zipcode"
                        v-validate="{required: true, digits: 6}"
                        :class="errors.has('updateDetails.zipcode') ? 'error' : ''"
                        data-vv-as="pincode"
                        name="updateDetails.zipcode"
                        placeholder=" "
                        type="text"
                      >
                      <span class="float-label">Pincode <i
                        aria-hidden="true"
                        class="fas fa-asterisk text-danger"
                        style="font-size: 7px; vertical-align: text-top;"
                      /></span>
                      <span
                        v-show="errors.has('updateDetails.zipcode')"
                        class="help text-danger"
                      >{{ errors.first('updateDetails.zipcode') }}</span>
                    </label>
                  </div>
                </div>
                <div
                  v-if="jobType === 'OutCaller'"
                  class="row"
                >
                  <div class="form-group col-md-offset-1 col-md-5">
                    <label class="has-float-label">
                      <label class="has-float-label">
                        <VueMultiselect
                          v-model="updateDetails.residenceType"
                          :allow-empty="false"
                          :class="{ 'placeholder-shown': (!updateDetails.residenceType || updateDetails.residenceType === '') }"
                          :options="getResidenceOptions"
                          :placeholder="null"
                          :show-labels="false"
                        />
                        <span class="float-label">Residence Type <i
                          aria-hidden="true"
                          class="fas fa-asterisk text-danger"
                          style="font-size: 7px; vertical-align: text-top;"
                        /></span>
                      </label>
                    </label>
                  </div>
                  <div class="form-group col-md-offset-1 col-md-4">
                    <label class="has-float-label">
                      <input
                        id="yearPicker"
                        v-model="updateDetails.stayingSince"
                        v-validate="'required|date_format:yyyy'"
                        :class="errors.has('updateDetails.stayingSince') ? 'error' : ''"
                        class="date-picker year-picker"
                        data-vv-as="staying since"
                        name="updateDetails.stayingSince"
                        placeholder=" "
                        type="text"
                      >
                      <span class="float-label">Staying Since <i
                        aria-hidden="true"
                        class="fas fa-asterisk text-danger"
                        style="font-size: 7px; vertical-align: text-top;"
                      /></span>
                      <span
                        v-show="errors.has('updateDetails.stayingSince')"
                        class="help text-danger"
                      >{{ errors.first('updateDetails.stayingSince') }}</span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="row m-b-lg">
              <div class="col-xs-12">
                <div class="row">
                  <div class="col-xs-12 text-center">
                    <h4>Employment Details</h4>
                  </div>
                </div>
                <div class="row">
                  <div class="form-group col-md-offset-1 col-md-5">
                    <div class="non-float-input">
                      <label>Employment Status: <i
                        v-if="!isYesBankForm"
                        aria-hidden="true"
                        class="fas fa-asterisk text-danger"
                        style="font-size: 7px; vertical-align: text-top;"
                      /></label>
                      <div class="radio-input-group">
                        <div class="radio-inline">
                          <label>
                            <input
                              v-model="updateDetails.employmentStatus"
                              v-validate="{ required: !isYesBankForm }"
                              :class="errors.has('updateDetails.employmentStatus') ? 'error' : ''"
                              data-vv-as="employment status"
                              name="updateDetails.employmentStatus"
                              type="radio"
                              value="SALARIED"
                            >Salaried
                          </label>
                        </div>
                        <div class="radio-inline">
                          <label>
                            <input
                              v-model="updateDetails.employmentStatus"
                              name="updateDetails.employmentStatus"
                              type="radio"
                              value="SELF EMPLOYED"
                            >Self Employed
                          </label>
                        </div>
                      </div>
                    </div>
                    <span
                      v-show="errors.has('updateDetails.employmentStatus')"
                      class="help text-danger"
                    >{{ errors.first('updateDetails.employmentStatus') }}</span>
                  </div>
                  <div class="form-group col-md-offset-1 col-md-4">
                    <label class="has-float-label">
                      <input
                        v-model="updateDetails.company"
                        v-validate="'required'"
                        :class="errors.has('updateDetails.company') ? 'error' : ''"
                        data-vv-as="company name"
                        name="updateDetails.company"
                        placeholder=" "
                        type="text"
                      >
                      <span class="float-label">Company Name <i
                        aria-hidden="true"
                        class="fas fa-asterisk text-danger"
                        style="font-size: 7px; vertical-align: text-top;"
                      /></span>
                      <span
                        v-show="errors.has('updateDetails.company')"
                        class="help text-danger"
                      >{{ errors.first('updateDetails.company') }}</span>
                    </label>
                  </div>
                </div>
                <div
                  v-if="jobType === 'OutCaller'"
                  class="row"
                >
                  <div class="form-group col-md-offset-1 col-md-5">
                    <label class="has-float-label">
                      <input
                        v-model="updateDetails.designation"
                        v-validate="'required'"
                        :class="errors.has('updateDetails.designation') ? 'error' : ''"
                        data-vv-as="designation"
                        name="updateDetails.designation"
                        placeholder=" "
                        type="text"
                      >
                      <span class="float-label">Designation <i
                        aria-hidden="true"
                        class="fas fa-asterisk text-danger"
                        style="font-size: 7px; vertical-align: text-top;"
                      /></span>
                      <span
                        v-show="errors.has('updateDetails.designation')"
                        class="help text-danger"
                      >{{ errors.first('updateDetails.designation') }}</span>
                    </label>
                  </div>
                  <div class="form-group col-md-offset-1 col-md-4">
                    <label class="has-float-label">
                      <input
                        v-model="updateDetails.yearsEmployed"
                        v-validate="'required|numeric|max_value:45'"
                        :class="errors.has('updateDetails.yearsEmployed') ? 'error' : ''"
                        data-vv-as="work experience"
                        name="updateDetails.yearsEmployed"
                        placeholder=" "
                        type="text"
                      >
                      <span class="float-label">Total Work Experience (Years) <i
                        aria-hidden="true"
                        class="fas fa-asterisk text-danger"
                        style="font-size: 7px; vertical-align: text-top;"
                      /></span>
                      <span
                        v-show="errors.has('updateDetails.yearsEmployed')"
                        class="help text-danger"
                      >{{ errors.first('updateDetails.yearsEmployed') }}</span>
                    </label>
                  </div>
                </div>
                <div
                  v-if="jobType === 'OutCaller'"
                  class="row"
                >
                  <div class="form-group col-md-offset-1 col-md-3 col-xs-9">
                    <label class="has-float-label">
                      <input
                        v-model="updateDetails.officeLandline"
                        v-validate="'digits:11'"
                        :class="errors.has('updateDetails.officeLandline') ? 'error' : ''"
                        data-vv-as="landline"
                        name="updateDetails.officeLandline"
                        placeholder=" "
                        type="text"
                      >
                      <span class="float-label">Landline</span>
                      <span
                        v-show="errors.has('updateDetails.officeLandline')"
                        class="help text-danger"
                      >{{ errors.first('updateDetails.officeLandline') }}</span>
                    </label>
                  </div>
                  <div class="form-group col-xs-3">
                    <label class="has-float-label">
                      <input
                        v-model="updateDetails.officeExtension"
                        v-validate="'numeric'"
                        :class="errors.has('updateDetails.officeExtension') ? 'error' : ''"
                        data-vv-as="extension"
                        name="updateDetails.officeExtension"
                        placeholder=" "
                        type="text"
                      >
                      <span class="float-label">Extension</span>
                      <span
                        v-show="errors.has('updateDetails.officeExtension')"
                        class="help text-danger"
                      >{{ errors.first('updateDetails.officeExtension') }}</span>
                    </label>
                  </div>
                  <div class="form-group col-xs-12 col-sm-12 col-md-4">
                    <label class="has-float-label">
                      <input
                        v-model="updateDetails.emailOfficial"
                        v-validate="{email: true, is_not: updateDetails.email}"
                        :class="errors.has('updateDetails.emailOfficial') ? 'error' : ''"
                        data-vv-as="official email"
                        name="updateDetails.emailOfficial"
                        placeholder=" "
                        type="text"
                      >
                      <span class="float-label">Official Email ID</span>
                      <span
                        v-show="errors.has('updateDetails.emailOfficial')"
                        class="help text-danger"
                      >{{ errors.first('updateDetails.emailOfficial') }}</span>
                    </label>
                  </div>
                </div>
                <div class="row">
                  <div class="form-group col-md-offset-1 col-md-5">
                    <label class="has-float-label">
                      <input
                        v-model="updateDetails.netMonthlyIncome"
                        v-validate="{ required: !isYesBankForm }"
                        :class="errors.has('updateDetails.netMonthlyIncome') ? 'error' : ''"
                        data-vv-as="net monthly income"
                        name="updateDetails.netMonthlyIncome"
                        placeholder=" "
                        type="text"
                      >
                      <span class="float-label">Net Monthly Salary / Income <i
                        v-if="!isYesBankForm"
                        aria-hidden="true"
                        class="fas fa-asterisk text-danger"
                        style="font-size: 7px; vertical-align: text-top;"
                      /></span>
                      <span
                        v-show="errors.has('updateDetails.netMonthlyIncome')"
                        class="help text-danger"
                      >{{ errors.first('updateDetails.netMonthlyIncome') }}</span>
                    </label>
                  </div>
                  <div class="form-group col-md-offset-1 col-md-4">
                    <div class="non-float-input">
                      <label>Company Listed ?</label>
                      <div class="radio-input-group">
                        <div class="radio-inline">
                          <label>
                            <input
                              v-model="updateDetails.companyListed"
                              :class="errors.has('updateDetails.companyListed') ? 'error' : ''"
                              name="updateDetails.companyListed"
                              type="radio"
                              value="LISTED"
                            >Yes
                          </label>
                        </div>
                        <div class="radio-inline">
                          <label>
                            <input
                              v-model="updateDetails.companyListed"
                              name="updateDetails.companyListed"
                              type="radio"
                              value="NOT LISTED"
                            >No
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xs-12 text-center">
                    <h5>Company Address</h5>
                  </div>
                </div>
                <div class="row">
                  <div class="form-group col-md-offset-1 col-md-5">
                    <label class="has-float-label">
                      <input
                        v-model="updateDetails.companyAddressLine1"
                        v-validate="{ required: !isYesBankForm && !isHDFCBankForm, min: 3, max: 39, regex: /^[\w\d\s]+$/ }"
                        :class="errors.has('updateDetails.companyAddressLine1') ? 'error' : ''"
                        data-vv-as="address line 1"
                        name="updateDetails.companyAddressLine1"
                        placeholder=" "
                        type="text"
                      >
                      <span class="float-label">Office / Gala No., Building No., Wing / Floor, Building Name <i
                        v-if="!isYesBankForm && !isHDFCBankForm"
                        aria-hidden="true"
                        class="fas fa-asterisk text-danger"
                        style="font-size: 7px; vertical-align: text-top;"
                      /></span>
                      <span
                        v-show="errors.has('updateDetails.companyAddressLine1')"
                        class="help text-danger"
                      >{{ errors.first('updateDetails.companyAddressLine1') }}</span>
                    </label>
                  </div>
                  <div class="form-group col-md-offset-1 col-md-4">
                    <label class="has-float-label">
                      <input
                        v-model="updateDetails.companyAddressLine2"
                        v-validate="{ required: !isYesBankForm && !isHDFCBankForm, regex: /^[\w\d\s]+$/ }"
                        :class="errors.has('updateDetails.companyAddressLine2') ? 'error' : ''"
                        data-vv-as="address line 2"
                        name="updateDetails.companyAddressLine2"
                        placeholder=" "
                        type="text"
                      >
                      <span class="float-label">Street Name, Sector, Plot No. <i
                        v-if="!isYesBankForm && !isHDFCBankForm"
                        aria-hidden="true"
                        class="fas fa-asterisk text-danger"
                        style="font-size: 7px; vertical-align: text-top;"
                      /></span>
                      <span
                        v-show="errors.has('updateDetails.companyAddressLine2')"
                        class="help text-danger"
                      >{{ errors.first('updateDetails.companyAddressLine2') }}</span>
                    </label>
                  </div>
                </div>
                <div class="row">
                  <div class="form-group col-md-offset-1 col-md-5">
                    <label class="has-float-label">
                      <input
                        v-model="updateDetails.companyLandmark"
                        v-validate="{ required: !isYesBankForm && !isHDFCBankForm, regex: /^[\w\d\s]+$/ }"
                        :class="errors.has('updateDetails.companyLandmark') ? 'error' : ''"
                        data-vv-as="landmark"
                        name="updateDetails.companyLandmark"
                        placeholder=" "
                        type="text"
                      >
                      <span class="float-label">Landmark <i
                        v-if="!isYesBankForm && !isHDFCBankForm"
                        aria-hidden="true"
                        class="fas fa-asterisk text-danger"
                        style="font-size: 7px; vertical-align: text-top;"
                      /></span>
                      <span
                        v-show="errors.has('updateDetails.companyLandmark')"
                        class="help text-danger"
                      >{{ errors.first('updateDetails.companyLandmark') }}</span>
                    </label>
                  </div>
                  <div class="form-group col-md-offset-1 col-md-4">
                    <label class="has-float-label">
                      <input
                        v-model="updateDetails.companyStation"
                        v-validate="{ required: !isYesBankForm && !isHDFCBankForm, regex: /^[\w\d\s]+$/ }"
                        :class="errors.has('updateDetails.companyStation') ? 'error' : ''"
                        data-vv-as="station"
                        name="updateDetails.companyStation"
                        placeholder=" "
                        type="text"
                      >
                      <span class="float-label">Station <i
                        v-if="!isYesBankForm && !isHDFCBankForm"
                        aria-hidden="true"
                        class="fas fa-asterisk text-danger"
                        style="font-size: 7px; vertical-align: text-top;"
                      /></span>
                      <span
                        v-show="errors.has('updateDetails.companyStation')"
                        class="help text-danger"
                      >{{ errors.first('updateDetails.companyStation') }}</span>
                    </label>
                  </div>
                  <div class="form-group col-md-offset-1 col-md-5">
                    <label class="has-float-label">
                      <input
                        v-model="updateDetails.companyCity"
                        v-validate="{ required: true, regex: /^[\w\d\s]+$/ }"
                        :class="errors.has('updateDetails.companyCity') ? 'error' : ''"
                        data-vv-as="city"
                        name="updateDetails.companyCity"
                        placeholder=" "
                        type="text"
                      >
                      <span class="float-label">City <i
                        aria-hidden="true"
                        class="fas fa-asterisk text-danger"
                        style="font-size: 7px; vertical-align: text-top;"
                      /></span>
                      <span
                        v-show="errors.has('updateDetails.companyCity')"
                        class="help text-danger"
                      >{{ errors.first('updateDetails.companyCity') }}</span>
                    </label>
                  </div>
                  <div class="form-group col-md-offset-1 col-md-4">
                    <label class="has-float-label">
                      <input
                        v-model="updateDetails.companyZipcode"
                        v-validate="{required: true, digits: 6}"
                        :class="errors.has('updateDetails.companyZipcode') ? 'error' : ''"
                        data-vv-as="pincode"
                        name="updateDetails.companyZipcode"
                        placeholder=" "
                        type="text"
                      >
                      <span class="float-label">Pincode <i
                        aria-hidden="true"
                        class="fas fa-asterisk text-danger"
                        style="font-size: 7px; vertical-align: text-top;"
                      /></span>
                      <span
                        v-show="errors.has('updateDetails.companyZipcode')"
                        class="help text-danger"
                      >{{ errors.first('updateDetails.companyZipcode') }}</span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="row m-b-lg">
              <div class="col-xs-12">
                <div class="row">
                  <div class="col-xs-12 text-center">
                    <h4>For Card To Card Offer</h4>
                  </div>
                </div>
                <div class="row">
                  <div class="form-group col-md-offset-1 col-md-5">
                    <label class="has-float-label">
                      <input
                        v-model="updateDetails.extCCBank1"
                        v-validate="{ required: isCardToCardOffer }"
                        :class="errors.has('updateDetails.extCCBank1') ? 'error' : ''"
                        data-vv-as="CC bank"
                        name="updateDetails.extCCBank1"
                        placeholder=" "
                        type="text"
                      >
                      <span class="float-label">1 Existing CC. Bank</span>
                      <span
                        v-show="errors.has('updateDetails.extCCBank1')"
                        class="help text-danger"
                      >{{ errors.first('updateDetails.extCCBank1') }}</span>
                    </label>
                  </div>
                  <div class="form-group col-md-offset-1 col-md-4">
                    <label class="has-float-label">
                      <input
                        v-model="updateDetails.extCCUsingFrom1"
                        v-validate="{ required: isCardToCardOffer, numeric: true, min_value: 1, max_value: 999 }"
                        :class="errors.has('updateDetails.extCCUsingFrom1') ? 'error' : ''"
                        data-vv-as="CC using from"
                        name="updateDetails.extCCUsingFrom1"
                        placeholder=" "
                        type="text"
                      >
                      <span class="float-label">Using From (In Months)</span>
                      <span
                        v-show="errors.has('updateDetails.extCCUsingFrom1')"
                        class="help text-danger"
                      >{{ errors.first('updateDetails.extCCUsingFrom1') }}</span>
                    </label>
                  </div>
                  <div class="form-group col-md-offset-1 col-md-5">
                    <label class="has-float-label">
                      <input
                        v-model="updateDetails.extCCTotalLimit1"
                        v-validate="{ required: isCardToCardOffer }"
                        :class="errors.has('updateDetails.extCCTotalLimit1') ? 'error' : ''"
                        data-vv-as="CC total limit"
                        name="updateDetails.extCCTotalLimit1"
                        placeholder=" "
                        type="text"
                      >
                      <span class="float-label">Total Limit</span>
                      <span
                        v-show="errors.has('updateDetails.extCCTotalLimit1')"
                        class="help text-danger"
                      >{{ errors.first('updateDetails.extCCTotalLimit1') }}</span>
                    </label>
                  </div>
                  <div class="form-group col-md-offset-1 col-md-4">
                    <label class="has-float-label">
                      <input
                        v-model="updateDetails.extCCAvailableLimit1"
                        v-validate="{ required: isCardToCardOffer }"
                        :class="errors.has('updateDetails.extCCAvailableLimit1') ? 'error' : ''"
                        data-vv-as="CC available limit"
                        name="updateDetails.extCCAvailableLimit1"
                        placeholder=" "
                        type="text"
                      >
                      <span class="float-label">Available Limit</span>
                      <span
                        v-show="errors.has('updateDetails.extCCAvailableLimit1')"
                        class="help text-danger"
                      >{{ errors.first('updateDetails.extCCAvailableLimit1') }}</span>
                    </label>
                  </div>
                </div>
                <div class="row">
                  <div class="form-group col-md-offset-1 col-md-5">
                    <label class="has-float-label">
                      <input
                        v-model="updateDetails.extCCBank2"
                        name="updateDetails.extCCBank2"
                        placeholder=" "
                        type="text"
                      >
                      <span class="float-label">2 Existing CC. Bank</span>
                    </label>
                  </div>
                  <div class="form-group col-md-offset-1 col-md-4">
                    <label class="has-float-label">
                      <input
                        v-model="updateDetails.extCCUsingFrom2"
                        v-validate="{ numeric: true, min_value: 1, max_value: 999 }"
                        :class="errors.has('updateDetails.extCCUsingFrom2') ? 'error' : ''"
                        data-vv-as="CC using from"
                        name="updateDetails.extCCUsingFrom2"
                        placeholder=" "
                        type="text"
                      >
                      <span class="float-label">Using From (In Months)</span>
                      <span
                        v-show="errors.has('updateDetails.extCCUsingFrom2')"
                        class="help text-danger"
                      >{{ errors.first('updateDetails.extCCUsingFrom2') }}</span>
                    </label>
                  </div>
                  <div class="form-group col-md-offset-1 col-md-5">
                    <label class="has-float-label">
                      <input
                        v-model="updateDetails.extCCTotalLimit2"
                        name="updateDetails.extCCTotalLimit2"
                        placeholder=" "
                        type="text"
                      >
                      <span class="float-label">Total Limit</span>
                    </label>
                  </div>
                  <div class="form-group col-md-offset-1 col-md-4">
                    <label class="has-float-label">
                      <input
                        v-model="updateDetails.extCCAvailableLimit2"
                        name="updateDetails.extCCAvailableLimit2"
                        placeholder=" "
                        type="text"
                      >
                      <span class="float-label">Available Limit</span>
                    </label>
                  </div>
                </div>
                <div
                  v-if="jobType === 'OutCaller'"
                  class="row"
                >
                  <div class="form-group col-md-offset-1 col-md-5">
                    <label class="has-float-label">
                      <input
                        v-model="updateDetails.extCCBank3"
                        name="updateDetails.extCCBank3"
                        placeholder=" "
                        type="text"
                      >
                      <span class="float-label">3 Existing CC. Bank</span>
                    </label>
                  </div>
                  <div class="form-group col-md-offset-1 col-md-4">
                    <label class="has-float-label">
                      <input
                        v-model="updateDetails.extCCUsingFrom3"
                        v-validate="{ numeric: true, min_value: 1, max_value: 999 }"
                        :class="errors.has('updateDetails.extCCUsingFrom3') ? 'error' : ''"
                        data-vv-as="CC using from"
                        name="updateDetails.extCCUsingFrom3"
                        placeholder=" "
                        type="text"
                      >
                      <span class="float-label">Using From (In Months)</span>
                      <span
                        v-show="errors.has('updateDetails.extCCUsingFrom3')"
                        class="help text-danger"
                      >{{ errors.first('updateDetails.extCCUsingFrom3') }}</span>
                    </label>
                  </div>
                  <div class="form-group col-md-offset-1 col-md-5">
                    <label class="has-float-label">
                      <input
                        v-model="updateDetails.extCCTotalLimit3"
                        name="updateDetails.extCCTotalLimit3"
                        placeholder=" "
                        type="text"
                      >
                      <span class="float-label">Total Limit</span>
                    </label>
                  </div>
                  <div class="form-group col-md-offset-1 col-md-4">
                    <label class="has-float-label">
                      <input
                        v-model="updateDetails.extCCAvailableLimit3"
                        name="updateDetails.extCCAvailableLimit3"
                        placeholder=" "
                        type="text"
                      >
                      <span class="float-label">Available Limit</span>
                    </label>
                  </div>
                </div>
                <div
                  v-if="jobType === 'OutCaller'"
                  class="row"
                >
                  <div class="form-group col-md-offset-1 col-md-5">
                    <label class="has-float-label">
                      <input
                        v-model="updateDetails.extCCBank4"
                        name="updateDetails.extCCBank4"
                        placeholder=" "
                        type="text"
                      >
                      <span class="float-label">4 Existing CC. Bank</span>
                    </label>
                  </div>
                  <div class="form-group col-md-offset-1 col-md-4">
                    <label class="has-float-label">
                      <input
                        v-model="updateDetails.extCCUsingFrom4"
                        v-validate="{ numeric: true, min_value: 1, max_value: 999 }"
                        :class="errors.has('updateDetails.extCCUsingFrom4') ? 'error' : ''"
                        data-vv-as="CC using from"
                        name="updateDetails.extCCUsingFrom4"
                        placeholder=" "
                        type="text"
                      >
                      <span class="float-label">Using From (In Months)</span>
                      <span
                        v-show="errors.has('updateDetails.extCCUsingFrom4')"
                        class="help text-danger"
                      >{{ errors.first('updateDetails.extCCUsingFrom4') }}</span>
                    </label>
                  </div>
                  <div class="form-group col-md-offset-1 col-md-5">
                    <label class="has-float-label">
                      <input
                        v-model="updateDetails.extCCTotalLimit4"
                        name="updateDetails.extCCTotalLimit4"
                        placeholder=" "
                        type="text"
                      >
                      <span class="float-label">Total Limit</span>
                    </label>
                  </div>
                  <div class="form-group col-md-offset-1 col-md-4">
                    <label class="has-float-label">
                      <input
                        v-model="updateDetails.extCCAvailableLimit4"
                        name="updateDetails.extCCAvailableLimit4"
                        placeholder=" "
                        type="text"
                      >
                      <span class="float-label">Available Limit</span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="jobType === 'OutCaller'"
              class="row m-b-lg"
            >
              <div class="col-xs-12">
                <div class="row">
                  <div class="col-xs-12 text-center">
                    <h4>Additional Details</h4>
                  </div>
                </div>
                <div class="row">
                  <div class="form-group col-md-offset-1 col-md-5">
                    <label class="has-float-label">
                      <input
                        v-model="updateDetails.extCCNumber"
                        v-validate="{ required: true, numeric: true, max: 16 }"
                        :class="errors.has('updateDetails.extCCNumber') ? 'error' : ''"
                        data-vv-as="Ext CC Number"
                        name="updateDetails.extCCNumber"
                        placeholder=" "
                        type="text"
                      >
                      <span class="float-label">Existing CC # <i
                        aria-hidden="true"
                        class="fas fa-asterisk text-danger"
                        style="font-size: 7px; vertical-align: text-top;"
                      /></span>
                      <span
                        v-show="errors.has('updateDetails.extCCNumber')"
                        class="help text-danger"
                      >{{ errors.first('updateDetails.extCCNumber') }}</span>
                    </label>
                  </div>
                  <div class="form-group col-md-offset-1 col-md-4">
                    <label class="has-float-label">
                      <label class="has-float-label">
                        <VueMultiselect
                          v-model="updateDetails.cardMailingAddress"
                          :allow-empty="false"
                          :class="{ 'placeholder-shown': (!updateDetails.cardMailingAddress || updateDetails.cardMailingAddress === '') }"
                          :options="getAddressTypeOptions"
                          :placeholder="null"
                          :show-labels="false"
                        />
                        <span class="float-label">Send Card To <i
                          aria-hidden="true"
                          class="fas fa-asterisk text-danger"
                          style="font-size: 7px; vertical-align: text-top;"
                        /></span>
                      </label>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <div
              v-if="!(showAllFields || showAllFieldsDefault)"
              class="row m-b-lg"
            >
              <div class="col-xs-12">
                <div class="row">
                  <div class="col-xs-12 text-center">
                    <h4>Personal Info</h4>
                  </div>
                </div>
                <div class="row">
                  <div class="form-group col-xs-offset-1 col-xs-5 col-sm-offset-3 col-sm-3">
                    <label
                      class="form-details-label"
                      for="firstNameRedacted"
                    >First Name:</label>
                    <p
                      id="firstNameRedacted"
                      class="text-muted"
                    >
                      {{ formDetails.firstName }}
                    </p>
                  </div>
                  <div class="form-group col-xs-offset-1 col-xs-5 col-sm-offset-1 col-sm-3">
                    <label
                      class="form-details-label"
                      for="lastNameRedacted"
                    >Last Name:</label>
                    <p
                      id="lastNameRedacted"
                      class="text-muted"
                    >
                      {{ formDetails.lastName }}
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div class="form-group col-xs-offset-1 col-xs-5 col-sm-offset-1 col-sm-3">
                    <label
                      class="form-details-label"
                      for="pancardRedacted"
                    >Pancard:</label>
                    <p
                      id="pancardRedacted"
                      class="text-muted"
                    >
                      {{ formDetails.pancard }}
                    </p>
                  </div>
                  <div class="form-group col-xs-offset-1 col-xs-5 col-sm-offset-1 col-sm-3">
                    <label
                      class="form-details-label"
                      for="emailRedacted"
                    >Personal Email ID:</label>
                    <p
                      id="emailRedacted"
                      class="text-muted"
                    >
                      {{ formDetails.email }}
                    </p>
                  </div>
                  <div class="form-group col-xs-offset-1 col-xs-5 col-sm-offset-1 col-sm-2">
                    <label
                      class="form-details-label"
                      for="mobileNumberRedacted"
                    >Mobile Number:</label>
                    <p
                      id="mobileNumberRedacted"
                      class="text-muted"
                    >
                      {{ formDetails.mobileNumber }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="showAllFields || showAllFieldsDefault"
              class="row m-b-lg"
            >
              <div class="col-xs-12">
                <div class="row">
                  <div class="col-xs-12 text-center">
                    <h4>Personal Info</h4>
                  </div>
                </div>
                <div class="row">
                  <div class="form-group col-xs-offset-1 col-xs-5 col-sm-offset-1 col-sm-1">
                    <label
                      class="form-details-label"
                      for="title"
                    >Title:</label>
                    <p
                      id="title"
                      class="text-muted"
                    >
                      {{ formDetails.title }}
                    </p>
                  </div>
                  <div class="form-group col-xs-offset-1 col-xs-5 col-sm-offset-2 col-sm-3">
                    <label
                      class="form-details-label"
                      for="firstName"
                    >First Name:</label>
                    <p
                      id="firstName"
                      class="text-muted"
                    >
                      {{ formDetails.firstName }}
                    </p>
                  </div>
                  <div class="form-group col-xs-offset-1 col-xs-5 col-sm-offset-0 col-sm-2">
                    <label
                      class="form-details-label"
                      for="lastName"
                    >Last Name:</label>
                    <p
                      id="lastName"
                      class="text-muted"
                    >
                      {{ formDetails.lastName }}
                    </p>
                  </div>
                  <div class="form-group col-xs-offset-1 col-xs-5 col-sm-offset-0 col-sm-2">
                    <label
                      class="form-details-label"
                      for="pancard"
                    >Pancard:</label>
                    <p
                      id="pancard"
                      class="text-muted"
                    >
                      {{ formDetails.pancard }}
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div class="form-group col-xs-offset-1 col-xs-5 col-sm-offset-1 col-sm-3">
                    <label
                      class="form-details-label"
                      for="nameDesired"
                    >Name Desired on Card:</label>
                    <p
                      id="nameDesired"
                      class="text-muted"
                    >
                      {{ formDetails.nameDesired }}
                    </p>
                  </div>
                  <div class="form-group col-xs-offset-1 col-xs-5 col-sm-offset-0 col-sm-3">
                    <label
                      class="form-details-label"
                      for="motherName"
                    >Mother Name:</label>
                    <p
                      id="motherName"
                      class="text-muted"
                    >
                      {{ formDetails.motherName }}
                    </p>
                  </div>
                  <div class="form-group col-xs-offset-1 col-xs-5 col-sm-offset-0 col-sm-3">
                    <label
                      class="form-details-label"
                      for="fatherName"
                    >Father Name:</label>
                    <p
                      id="fatherName"
                      class="text-muted"
                    >
                      {{ formDetails.fatherName }}
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div class="form-group col-xs-offset-1 col-xs-5 col-sm-offset-1 col-sm-3">
                    <label
                      class="form-details-label"
                      for="dateOfBirth"
                    >Date Of Birth:</label>
                    <p
                      id="dateOfBirth"
                      class="text-muted"
                    >
                      {{ prettyPrint(formDetails.dateOfBirth, 'Date') }}
                    </p>
                  </div>
                  <div class="form-group col-xs-offset-1 col-xs-5 col-sm-offset-0 col-sm-2">
                    <label
                      class="form-details-label"
                      for="age"
                    >Age:</label>
                    <p
                      id="age"
                      class="text-muted"
                    >
                      {{ formDetails.age }}
                    </p>
                  </div>
                  <div class="form-group col-xs-offset-1 col-xs-5 col-sm-offset-1 col-sm-2">
                    <label
                      class="form-details-label"
                      for="gender"
                    >Gender:</label>
                    <p
                      id="gender"
                      class="text-muted"
                    >
                      {{ formDetails.gender }}
                    </p>
                  </div>
                  <div class="form-group col-xs-offset-1 col-xs-5 col-sm-offset-0 col-sm-2">
                    <label
                      class="form-details-label"
                      for="maritalStatus"
                    >Marital Status:</label>
                    <p
                      id="maritalStatus"
                      class="text-muted"
                    >
                      {{ formDetails.maritalStatus }}
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div class="form-group col-xs-offset-1 col-xs-5 col-sm-offset-1 col-sm-2">
                    <label
                      class="form-details-label"
                      for="education"
                    >Education:</label>
                    <p
                      id="education"
                      class="text-muted"
                    >
                      {{ formDetails.education }}
                    </p>
                  </div>
                  <div class="form-group col-xs-offset-1 col-xs-5 col-sm-offset-1 col-sm-3">
                    <label
                      class="form-details-label"
                      for="email"
                    >Personal Email ID:</label>
                    <p
                      id="email"
                      class="text-muted"
                    >
                      {{ formDetails.email }}
                    </p>
                  </div>
                  <div class="form-group col-xs-offset-1 col-xs-5 col-sm-offset-0 col-sm-2">
                    <label
                      class="form-details-label"
                      for="mobileNumber"
                    >Mobile Number:</label>
                    <p
                      id="mobileNumber"
                      class="text-muted"
                    >
                      {{ formDetails.mobileNumber }}
                    </p>
                  </div>
                  <div class="form-group col-xs-offset-1 col-xs-5 col-sm-offset-0 col-sm-2">
                    <label
                      class="form-details-label"
                      for="mobileNumberAlternate"
                    >Alternate Number:</label>
                    <p
                      id="mobileNumberAlternate"
                      class="text-muted"
                    >
                      {{ formDetails.mobileNumberAlternate }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="showAllFields || showAllFieldsDefault"
              class="row m-b-lg"
            >
              <div class="col-xs-12">
                <div class="row">
                  <div class="col-xs-12 text-center">
                    <h4>Resident Address Details (As Per Document Only)</h4>
                  </div>
                </div>
                <div class="row">
                  <div class="form-group col-xs-offset-1 col-xs-5">
                    <label
                      class="form-details-label"
                      for="addressLine1"
                    >Address Line 1:</label>
                    <p
                      id="addressLine1"
                      class="text-muted"
                    >
                      {{ formDetails.addressLine1 }}
                    </p>
                  </div>
                  <div class="form-group col-xs-offset-1 col-xs-5">
                    <label
                      class="form-details-label"
                      for="addressLine2"
                    >Address Line 2:</label>
                    <p
                      id="addressLine2"
                      class="text-muted"
                    >
                      {{ formDetails.addressLine2 }}
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div class="form-group col-xs-offset-1 col-xs-5 col-sm-offset-1 col-sm-2">
                    <label
                      class="form-details-label"
                      for="landmark"
                    >Landmark:</label>
                    <p
                      id="landmark"
                      class="text-muted"
                    >
                      {{ formDetails.landmark }}
                    </p>
                  </div>
                  <div class="form-group col-xs-offset-1 col-xs-5 col-sm-offset-1 col-sm-2">
                    <label
                      class="form-details-label"
                      for="station"
                    >Station:</label>
                    <p
                      id="station"
                      class="text-muted"
                    >
                      {{ formDetails.station }}
                    </p>
                  </div>
                  <div class="form-group col-xs-offset-1 col-xs-5 col-sm-offset-1 col-sm-2">
                    <label
                      class="form-details-label"
                      for="city"
                    >City:</label>
                    <p
                      id="city"
                      class="text-muted"
                    >
                      {{ formDetails.city }}
                    </p>
                  </div>
                  <div class="form-group col-xs-offset-1 col-xs-5 col-sm-offset-0 col-sm-2">
                    <label
                      class="form-details-label"
                      for="zipcode"
                    >Pincode:</label>
                    <p
                      id="zipcode"
                      class="text-muted"
                    >
                      {{ formDetails.zipcode }}
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div class="form-group col-xs-offset-1 col-xs-5 col-sm-offset-4 col-sm-3">
                    <label
                      class="form-details-label"
                      for="residenceType"
                    >Residence Type:</label>
                    <p
                      id="residenceType"
                      class="text-muted"
                    >
                      {{ formDetails.residenceType }}
                    </p>
                  </div>
                  <div class="form-group col-xs-offset-1 col-xs-5 col-sm-offset-0 col-sm-2">
                    <label
                      class="form-details-label"
                      for="stayingSince"
                    >Staying Since:</label>
                    <p
                      id="stayingSince"
                      class="text-muted"
                    >
                      {{ formDetails.stayingSince }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="showAllFields || showAllFieldsDefault"
              class="row m-b-lg"
            >
              <div class="col-xs-12">
                <div class="row">
                  <div class="col-xs-12 text-center">
                    <h4>Employment Details</h4>
                  </div>
                </div>
                <div class="row">
                  <div class="form-group col-xs-offset-1 col-xs-5">
                    <label
                      class="form-details-label"
                      for="employmentStatus"
                    >Employment
                      Status:</label>
                    <p
                      id="employmentStatus"
                      class="text-muted"
                    >
                      {{ formDetails.employmentStatus }}
                    </p>
                  </div>
                  <div class="form-group col-xs-offset-1 col-xs-5">
                    <label
                      class="form-details-label"
                      for="company"
                    >Company Name:</label>
                    <p
                      id="company"
                      class="text-muted"
                    >
                      {{ formDetails.company }}
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div class="form-group col-xs-offset-1 col-xs-5">
                    <label
                      class="form-details-label"
                      for="netMonthlyIncome"
                    >Net Monthly Salary / Income:</label>
                    <p
                      id="netMonthlyIncome"
                      class="text-muted"
                    >
                      {{ prettyPrint(formDetails.netMonthlyIncome, 'Currency') }}
                    </p>
                  </div>
                  <div class="form-group col-xs-offset-1 col-xs-5">
                    <label
                      class="form-details-label"
                      for="companyListed"
                    >Company Listed
                      ?</label>
                    <p
                      id="companyListed"
                      class="text-muted"
                    >
                      {{ formDetails.companyListed }}
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div class="form-group col-xs-offset-1 col-xs-5 col-sm-offset-2 col-sm-4">
                    <label
                      class="form-details-label"
                      for="designation"
                    >Designation:</label>
                    <p
                      id="designation"
                      class="text-muted"
                    >
                      {{ formDetails.designation }}
                    </p>
                  </div>
                  <div class="form-group col-xs-offset-1 col-xs-5 col-sm-offset-1 col-sm-4">
                    <label
                      class="form-details-label"
                      for="yearsEmployed"
                    >Total Work Experience:</label>
                    <p
                      id="yearsEmployed"
                      class="text-muted"
                    >
                      {{ formDetails.yearsEmployed }}
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div class="form-group col-xs-offset-1 col-xs-5 col-sm-offset-1 col-sm-3">
                    <label
                      class="form-details-label"
                      for="landline"
                    >Landline:</label>
                    <p
                      id="landline"
                      class="text-muted"
                    >
                      {{ formDetails.officeLandline }}
                    </p>
                  </div>
                  <div class="form-group col-xs-offset-1 col-xs-5 col-sm-offset-0 col-sm-3">
                    <label
                      class="form-details-label"
                      for="extension"
                    >Extension:</label>
                    <p
                      id="extension"
                      class="text-muted"
                    >
                      {{ formDetails.officeExtension }}
                    </p>
                  </div>
                  <div class="form-group col-xs-offset-1 col-xs-5 col-sm-offset-0 col-sm-4">
                    <label
                      class="form-details-label"
                      for="emailOfficial"
                    >Official Email ID:</label>
                    <p
                      id="emailOfficial"
                      class="text-muted"
                    >
                      {{ formDetails.emailOfficial }}
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xs-12 text-center">
                    <h5>Company Address</h5>
                  </div>
                </div>
                <div class="row">
                  <div class="form-group col-xs-offset-1 col-xs-5">
                    <label
                      class="form-details-label"
                      for="companyAddressLine1"
                    >Address Line 1:</label>
                    <p
                      id="companyAddressLine1"
                      class="text-muted"
                    >
                      {{ formDetails.companyAddressLine1 }}
                    </p>
                  </div>
                  <div class="form-group col-xs-offset-1 col-xs-5">
                    <label
                      class="form-details-label"
                      for="companyAddressLine2"
                    >Address Line 2:</label>
                    <p
                      id="companyAddressLine2"
                      class="text-muted"
                    >
                      {{ formDetails.companyAddressLine2 }}
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div class="form-group col-xs-offset-1 col-xs-5 col-sm-offset-1 col-sm-2">
                    <label
                      class="form-details-label"
                      for="companyLandmark"
                    >Landmark:</label>
                    <p
                      id="companyLandmark"
                      class="text-muted"
                    >
                      {{ formDetails.companyLandmark }}
                    </p>
                  </div>
                  <div class="form-group col-xs-offset-1 col-xs-5 col-sm-offset-1 col-sm-2">
                    <label
                      class="form-details-label"
                      for="companyStation"
                    >Station:</label>
                    <p
                      id="companyStation"
                      class="text-muted"
                    >
                      {{ formDetails.companyStation }}
                    </p>
                  </div>
                  <div class="form-group col-xs-offset-1 col-xs-5 col-sm-offset-1 col-sm-2">
                    <label
                      class="form-details-label"
                      for="companyCity"
                    >City:</label>
                    <p
                      id="companyCity"
                      class="text-muted"
                    >
                      {{ formDetails.companyCity }}
                    </p>
                  </div>
                  <div class="form-group col-xs-offset-1 col-xs-5 col-sm-offset-0 col-sm-2">
                    <label
                      class="form-details-label"
                      for="companyZipcode"
                    >Pincode:</label>
                    <p
                      id="companyZipcode"
                      class="text-muted"
                    >
                      {{ formDetails.companyZipcode }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="showAllFields || showAllFieldsDefault"
              class="row m-b-lg"
            >
              <div class="col-xs-12">
                <div class="row">
                  <div class="col-xs-12 text-center">
                    <h4>For Card To Card Offer</h4>
                  </div>
                </div>
                <div class="row">
                  <div class="form-group col-xs-offset-1 col-xs-3">
                    <label
                      class="form-details-label"
                      for="extCCBank1"
                    >1 Existing CC. Bank:</label>
                    <p
                      id="extCCBank1"
                      class="text-muted"
                    >
                      {{ formDetails.extCCBank1 }}
                    </p>
                  </div>
                  <div class="form-group col-xs-3">
                    <label
                      class="form-details-label"
                      for="extCCUsingFrom1"
                    >Using From (In Months):</label>
                    <p
                      id="extCCUsingFrom1"
                      class="text-muted"
                    >
                      {{ formDetails.extCCUsingFrom1 }}
                    </p>
                  </div>
                  <div class="form-group col-xs-2">
                    <label
                      class="form-details-label"
                      for="extCCTotalLimit1"
                    >Total Limit:</label>
                    <p
                      id="extCCTotalLimit1"
                      class="text-muted"
                    >
                      {{ prettyPrint(formDetails.extCCTotalLimit1, 'Currency') }}
                    </p>
                  </div>
                  <div class="form-group col-xs-3">
                    <label
                      class="form-details-label"
                      for="extCCAvailableLimit1"
                    >Available Limit:</label>
                    <p
                      id="extCCAvailableLimit1"
                      class="text-muted"
                    >
                      {{ prettyPrint(formDetails.extCCAvailableLimit1, 'Currency') }}
                    </p>
                  </div>
                </div>
                <div
                  v-if="formDetails.extCCBank2 && formDetails.extCCBank2 !== ''"
                  class="row"
                >
                  <div class="form-group col-xs-offset-1 col-xs-3">
                    <label
                      class="form-details-label"
                      for="extCCBank2"
                    >2 Existing CC. Bank:</label>
                    <p
                      id="extCCBank2"
                      class="text-muted"
                    >
                      {{ formDetails.extCCBank2 }}
                    </p>
                  </div>
                  <div class="form-group col-xs-3">
                    <label
                      class="form-details-label"
                      for="extCCUsingFrom2"
                    >Using From (In Months):</label>
                    <p
                      id="extCCUsingFrom2"
                      class="text-muted"
                    >
                      {{ formDetails.extCCUsingFrom2 }}
                    </p>
                  </div>
                  <div class="form-group col-xs-2">
                    <label
                      class="form-details-label"
                      for="extCCTotalLimit2"
                    >Total Limit:</label>
                    <p
                      id="extCCTotalLimit2"
                      class="text-muted"
                    >
                      {{ prettyPrint(formDetails.extCCTotalLimit2, 'Currency') }}
                    </p>
                  </div>
                  <div class="form-group col-xs-3">
                    <label
                      class="form-details-label"
                      for="extCCAvailableLimit2"
                    >Available Limit:</label>
                    <p
                      id="extCCAvailableLimit2"
                      class="text-muted"
                    >
                      {{ prettyPrint(formDetails.extCCAvailableLimit2, 'Currency') }}
                    </p>
                  </div>
                </div>
                <div
                  v-if="formDetails.extCCBank3 && formDetails.extCCBank3 !== ''"
                  class="row"
                >
                  <div class="form-group col-xs-offset-1 col-xs-3">
                    <label
                      class="form-details-label"
                      for="extCCBank3"
                    >3 Existing CC. Bank:</label>
                    <p
                      id="extCCBank3"
                      class="text-muted"
                    >
                      {{ formDetails.extCCBank3 }}
                    </p>
                  </div>
                  <div class="form-group col-xs-3">
                    <label
                      class="form-details-label"
                      for="extCCUsingFrom3"
                    >Using From (In Months):</label>
                    <p
                      id="extCCUsingFrom3"
                      class="text-muted"
                    >
                      {{ formDetails.extCCUsingFrom3 }}
                    </p>
                  </div>
                  <div class="form-group col-xs-2">
                    <label
                      class="form-details-label"
                      for="extCCTotalLimit3"
                    >Total Limit:</label>
                    <p
                      id="extCCTotalLimit3"
                      class="text-muted"
                    >
                      {{ prettyPrint(formDetails.extCCTotalLimit3, 'Currency') }}
                    </p>
                  </div>
                  <div class="form-group col-xs-3">
                    <label
                      class="form-details-label"
                      for="extCCAvailableLimit3"
                    >Available Limit:</label>
                    <p
                      id="extCCAvailableLimit3"
                      class="text-muted"
                    >
                      {{ prettyPrint(formDetails.extCCAvailableLimit3, 'Currency') }}
                    </p>
                  </div>
                </div>
                <div
                  v-if="formDetails.extCCBank4 && formDetails.extCCBank4 !== ''"
                  class="row"
                >
                  <div class="form-group col-xs-offset-1 col-xs-3">
                    <label
                      class="form-details-label"
                      for="extCCBank4"
                    >4 Existing CC. Bank:</label>
                    <p
                      id="extCCBank4"
                      class="text-muted"
                    >
                      {{ formDetails.extCCBank4 }}
                    </p>
                  </div>
                  <div class="form-group col-xs-3">
                    <label
                      class="form-details-label"
                      for="extCCUsingFrom4"
                    >Using From (In Months):</label>
                    <p
                      id="extCCUsingFrom4"
                      class="text-muted"
                    >
                      {{ formDetails.extCCUsingFrom4 }}
                    </p>
                  </div>
                  <div class="form-group col-xs-2">
                    <label
                      class="form-details-label"
                      for="extCCTotalLimit4"
                    >Total Limit:</label>
                    <p
                      id="extCCTotalLimit4"
                      class="text-muted"
                    >
                      {{ prettyPrint(formDetails.extCCTotalLimit4, 'Currency') }}
                    </p>
                  </div>
                  <div class="form-group col-xs-3">
                    <label
                      class="form-details-label"
                      for="extCCAvailableLimit4"
                    >Available Limit:</label>
                    <p
                      id="extCCAvailableLimit4"
                      class="text-muted"
                    >
                      {{ prettyPrint(formDetails.extCCAvailableLimit4, 'Currency') }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="showAllFields || showAllFieldsDefault"
              class="row m-b-lg"
            >
              <div class="col-xs-12">
                <div class="row">
                  <div class="col-xs-12 text-center">
                    <h4>Additional Details</h4>
                  </div>
                </div>
                <div class="row">
                  <div class="form-group col-xs-offset-1 col-sm-offset-1 col-xs-5">
                    <label
                      class="form-details-label"
                      for="extCCNumber"
                    >Ext CC #:</label>
                    <p
                      id="extCCNumber"
                      class="text-muted"
                    >
                      {{ formDetails.extCCNumber }}
                    </p>
                  </div>
                  <div class="form-group col-xs-offset-1 col-sm-offset-1 col-xs-5">
                    <label
                      class="form-details-label"
                      for="cardMailingAddress"
                    >Send Card To:</label>
                    <p
                      id="cardMailingAddress"
                      class="text-muted"
                    >
                      {{ formDetails.cardMailingAddress }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="showHistory && formDetails.logs && formDetails.logs.items"
            class="row m-b-lg"
          >
            <div class="col-xs-12">
              <div class="row">
                <div class="col-xs-12 text-center">
                  <h4>Form History</h4>
                </div>
              </div>
              <div
                v-for="log in formDetails.logs.items"
                :key="log.id"
                class="row"
              >
                <div class="form-group col-xs-offset-1 col-xs-3">
                  <label
                    :for="'owner-' + log.id"
                    class="form-details-label"
                  >Owner:</label>
                  <p
                    :id="'owner-' + log.id"
                    class="text-muted"
                  >
                    {{ prettyPrint(log.owner, 'User') }}
                  </p>
                </div>
                <div class="form-group col-xs-3">
                  <label
                    :for="'action-' + log.id"
                    class="form-details-label"
                  >Action:</label>
                  <p
                    :id="'action-' + log.id"
                    class="text-muted"
                  >
                    {{ log.actionType === 'STATUS' ? prettyPrint(log.action, 'Status') : log.action }}
                  </p>
                </div>
                <div class="form-group col-xs-3">
                  <label
                    :for="'time-' + log.id"
                    class="form-details-label"
                  >Time:</label>
                  <p
                    :id="'time-' + log.id"
                    class="text-muted"
                  >
                    {{ prettyPrint(log.actionEpoch, 'Epoch') }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="jobType === 'User'"
          class="modal-footer"
        >
          <div
            v-if="formDetails.status !== 'd73d381f-cf14-4388-9ec3-784a763c5431' && formDetails.status !== 'bff3d14d-4ad2-4d5e-a1af-50ba3261cf93'"
            class="pull-left"
          >
            <label
              class="m-r-sm"
              for="documentSubmissionLink"
            >Document Submission Link: </label>
            <a
              v-if="formDetails.documentSubmissionLink"
              id="documentSubmissionLinkUser"
              :href="(formDetails.documentSubmissionLink && formDetails.documentSubmissionLink !== '') ? formDetails.documentSubmissionLink : '#'"
              class="text-info form-control-static"
              target="_blank"
            >{{ formDetails.documentSubmissionLink }}</a>
            <button
              v-if="!formDetails.documentSubmissionLink || formDetails.documentSubmissionLink === ''"
              class="btn btn-addon btn-danger"
              type="button"
              @click.stop.prevent="generateUniqueLink"
            >
              <i class="fas fa-link" />
              Get Document Submission Link
            </button>
          </div>
          <button
            class="btn btn-default"
            data-dismiss="modal"
            type="button"
          >
            Close
          </button>
          <button
            v-if="formDetails.status === 'd73d381f-cf14-4388-9ec3-784a763c5431' || formDetails.status === 'bff3d14d-4ad2-4d5e-a1af-50ba3261cf93'"
            id="customerNotInterestedButton"
            class="btn btn-danger pull-left"
            type="button"
            @click.stop.prevent="updateCustomerResponse('No')"
          >
            Customer Not Interested
          </button>
          <button
            v-if="formDetails.status === 'd73d381f-cf14-4388-9ec3-784a763c5431'"
            id="customerInterestedButton"
            class="btn btn-success"
            type="button"
            @click.stop.prevent="updateCustomerResponse('Yes')"
          >
            Customer Interested
          </button>
          <button
            v-if="formDetails.status === 'bff3d14d-4ad2-4d5e-a1af-50ba3261cf93'"
            id="documentsReceivedButton"
            class="btn btn-success"
            type="button"
            @click.stop.prevent="updateDocumentsReceived"
          >
            Documents Received
          </button>
        </div>
        <div
          v-else
          class="modal-footer"
        >
          <div class="pull-left">
            <label
              class="m-r-sm"
              for="documentSubmissionLink"
            >Document Submission Link: </label>
            <a
              v-if="formDetails.documentSubmissionLink"
              id="documentSubmissionLink"
              :href="(formDetails.documentSubmissionLink && formDetails.documentSubmissionLink !== '') ? formDetails.documentSubmissionLink : '#'"
              class="text-info form-control-static"
              target="_blank"
            >{{ formDetails.documentSubmissionLink }}</a>
            <button
              v-if="jobType === 'BackOffice' && (!formDetails.documentSubmissionLink || formDetails.documentSubmissionLink === '')"
              class="btn btn-addon btn-danger"
              type="button"
              @click.stop.prevent="generateUniqueLink"
            >
              <i class="fas fa-link" />
              Get Document Submission Link
            </button>
          </div>
          <button
            class="btn btn-default"
            data-dismiss="modal"
            type="button"
          >
            Close
          </button>
          <button
            v-if="!reportOnly"
            id="updateSelectedForm"
            class="btn btn-default btn-info"
            type="button"
            @click.stop.prevent="updateSelectedForm"
          >
            Update
          </button>
          <button
            v-else-if="jobType === 'Owner'"
            id="updateFormOwner"
            class="btn btn-default btn-info"
            type="button"
            @click.stop.prevent="updateFormOwner"
          >
            Update
          </button>
          <button
            v-if="jobType === 'Owner'"
            id="deleteSelectedForm"
            class="btn btn-default btn-danger"
            type="button"
            @click.stop.prevent="deleteSelectedForm"
          >
            Delete
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import {API, Auth, graphqlOperation, Storage} from "aws-amplify";
import * as customQueries from "../graphql/customQueries";
import * as mutations from "../graphql/mutations";
import VueMultiselect from "vue-multiselect";
import {Validator} from 'vee-validate';
import {addressTypes, educationTypes, formFields, oclPincodes, residenceTypes, simplifiedFormFields} from "@/constants";
import {yesBranchCodesList} from "@/yesBranchCodes";

export default {
  name: "AIPFormModal",
  components: {
    VueMultiselect
  },
  props: {
    jobType: {
      type: String,
      required: true
    },
    formDetails: {
      type: Object,
      required: true
    },
    reportOnly: {
      type: Boolean,
      default: true
    },
    showHistory: {
      type: Boolean,
      default: false
    },
    showAllFields: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      formDocuments: [],
      updateDetails: {},
      appIdConfirmation: ''
    }
  },
  computed: {
    ...mapGetters([
      "getLoggedInUser",
      "getSettings",
      "getStatuses",
      "getBranches",
      "getProducts",
      "getUsers",
      "getTitles",
      "getForms"
    ]),
    getOclPincodes: function () {
      return oclPincodes;
    },
    _getUsers: function () {
      return _.sortBy(_.filter(this.getUsers, (user) => {
        return user.jobTitle === _.find(this.getTitles, (title) => {
          return title.access === 'User'
        }).id;
      }), 'username');
    },
    getAddressTypeOptions: function () {
      return addressTypes;
    },
    getEducationOptions: function () {
      return educationTypes;
    },
    getResidenceOptions: function () {
      return residenceTypes;
    },
    isYesBankForm: function () {
      return this.formDetails.product === '2a32cec2-1d11-48a6-84aa-9a8c9fd33c12';
    },
    isHDFCBankForm: function () {
      return this.formDetails.product === '597c5c1a-bc21-47c9-a69d-69a4c68f73ab';
    },
    isCardToCardOffer: function () {
      return this.formDetails.offerType === 'CARD TO CARD';
    },
    isEditable: function () {
      return this.updateDetails.status && _.includes(this.updateDetails.status.editable, this.jobType);
    },
    calcAge: function () {
      if (this.updateDetails.dateOfBirth && this.updateDetails.dateOfBirth !== '') {
        let today = new Date();
        let dateChunks = this.updateDetails.dateOfBirth.split('-');
        let birthDate = new Date(dateChunks[2] + '-' + dateChunks[1] + '-' + dateChunks[0]);
        let age = today.getFullYear() - birthDate.getFullYear();
        let m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
          age--;
        }
        if (birthDate instanceof Date && !isNaN(age)) {
          if (age < 0 || age > 100) {
            return 0;
          } else {
            return age;
          }
        } else {
          return null;
        }
      } else {
        return null;
      }
    },
    _getStatuses: function () {
      return _.sortBy(_.filter(this.getStatuses, (status) => {
        return _.includes(status.selectable, this.jobType);
      }), 'name');
    },
    editJustification() {
      if (this.updateDetails.status) {
        return _.result(_.find(this._getStatuses, (status) => {
          return status.id === this.updateDetails.status.id;
        }), 'justifications', null) !== null;
      } else {
        return false;
      }
    },
    _getStatusJustifications: function () {
      if (this.updateDetails.status) {
        return _.sortBy(_.result(_.find(this._getStatuses, (status) => {
          return status.id === this.updateDetails.status.id
        }), 'justifications', []));
      } else {
        return [];
      }
    },
    showAllFieldsDefault: function () {
      return this.updateDetails.status && _.includes(this.updateDetails.status.allFields, this.jobType);
    }
  },
  watch: {
    formDetails(newValue) {
      if (newValue) {
        this.appIdConfirmation = '';
        this.updateDetails = _.cloneDeep(newValue);

        this.updateDetails.status = _.find(this.getStatuses, (status) => {
          return status.id === newValue.status;
        }) || '';
        this.updateDetails.product = _.find(this.getProducts, (product) => {
          return product.id === newValue.product;
        }) || '';
        this.updateDetails.owner = _.find(this.getUsers, (user) => {
          return user.username === newValue.owner;
        }) || '';

        if (!_.isEmpty(newValue.dateOfBirth)) {
          let dateChunks = newValue.dateOfBirth.split('-');
          this.updateDetails.dateOfBirth = dateChunks[2] + '-' + dateChunks[1] + '-' + dateChunks[0];
        }

        if (!_.isEmpty(newValue.previouslyAppliedDate)) {
          let dateChunks = newValue.previouslyAppliedDate.split('-');
          this.updateDetails.previouslyAppliedDate = dateChunks[2] + '-' + dateChunks[1] + '-' + dateChunks[0];
        }

        if (!_.isEmpty(newValue.bankReceivedDate)) {
          let dateChunks = newValue.bankReceivedDate.split('-');
          this.updateDetails.bankReceivedDate = dateChunks[2] + '-' + dateChunks[1] + '-' + dateChunks[0];
        }

        if (!_.isEmpty(newValue.bankResultDate)) {
          let dateChunks = newValue.bankResultDate.split('-');
          this.updateDetails.bankResultDate = dateChunks[2] + '-' + dateChunks[1] + '-' + dateChunks[0];
        }

        this.getFormDocuments(newValue.submittedDocuments);
      } else {
        this.updateDetails = {};
      }
    },
    'updateDetails.status'() {
      this.$nextTick(function () {
        this.bindInputFieldEvents();
        if (this.updateDetails.status && this.updateDetails.status.name === 'ALREADY APPLIED') {
          let previouslyAppliedDatePicker = $('.previously-date-picker');
          previouslyAppliedDatePicker.datepicker({
            autoclose: true,
            format: "dd-mm-yyyy",
            startDate: "-90d",
            endDate: "0d"
          });
          previouslyAppliedDatePicker.change((e) => {
            this.updateDetails.previouslyAppliedDate = $(e.target).val();
          });
        }
        if (this.updateDetails.status && (this.updateDetails.status.name === 'COMPLETED' || this.updateDetails.status.name === 'OUTCALL - DONE')) {
          $('#yearPicker').datepicker({
            autoclose: true,
            format: "yyyy",
            viewMode: "years",
            minViewMode: "years"
          });
          $('.year-picker').change((e) => {
            this.updateDetails.stayingSince = $(e.target).val();
          });
        }
      });
    }
  },
  beforeMount() {
    const dictionary = {
      en: {
        messages: {
          included: () => 'Pincode is Out of City Limit'
        }
      }
    };

    // Override and merge the dictionaries
    Validator.localize(dictionary);

    Validator.extend('age-check', {
      getMessage: () => 'The age must be 23-60 years',
      validate: (value) => {
        let today = new Date();
        let dateChunks = value.split('-');
        let birthDate = new Date(dateChunks[2] + '-' + dateChunks[1] + '-' + dateChunks[0]);
        let age = today.getFullYear() - birthDate.getFullYear();
        let m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
          age--;
        }
        if (birthDate instanceof Date && !isNaN(age)) {
          return !(age < 23 || age > 60);
        } else {
          return false;
        }
      }
    });
  },
  mounted() {
    this.bindInputFieldEvents();
    $('.dob-picker').change((e) => {
      this.updateDetails.dateOfBirth = $(e.target).val();
    });
    $('.previously-date-picker').change((e) => {
      this.updateDetails.previouslyAppliedDate = $(e.target).val();
    });
    $('.year-picker').change((e) => {
      this.updateDetails.stayingSince = $(e.target).val();
    });
    $(':radio').click((e) => {
      e.preventDefault();
      e.stopPropagation();

      _.set(this, e.currentTarget.name, e.currentTarget.value);
    });
    if (this.jobType === 'MIS') {
      let bankReceivedDatePicker = $('.received-date-picker');
      bankReceivedDatePicker.datepicker({
        autoclose: true,
        format: "dd-mm-yyyy",
        endDate: "0d"
      });
      bankReceivedDatePicker.change((e) => {
        this.updateDetails.bankReceivedDate = $(e.target).val();
      });
      let bankResultDatePicker = $('.result-date-picker');
      bankResultDatePicker.datepicker({
        autoclose: true,
        format: "dd-mm-yyyy",
        endDate: "0d"
      });
      bankResultDatePicker.change((e) => {
        this.updateDetails.bankResultDate = $(e.target).val();
      });
    }
  },
  methods: {
    ...mapMutations([
      "UPDATE_FORMS",
      "UPDATE_UPDATE_SUBSCRIPTION_FORM",
      "UPDATE_DELETE_SUBSCRIPTION_FORM"
    ]),
    setMessage: function (type, title, message, timeout = 6000) {
      let content = this.$Amplify.I18n.get(message.message || message);

      if (type === 'alert' || type === 'confirm') {
        return this.$Msg.add(content, {
          type: type,
          position: "top-center",
          timeout: timeout,
          title: title
        });
      } else {
        this.$Msg.add(content, {
          theme: type,
          position: "top-center",
          timeout: timeout,
          title: title
        });
      }
    },
    bindInputFieldEvents: function () {
      _.forOwn(formFields, (field, fieldKey) => {
        let fieldElement = $('input[name="updateDetails.' + fieldKey + '"]');
        fieldElement.off();
        fieldElement.keypress((e) => {
          e.preventDefault();
          if (field !== 'Radio') {
            if (field === 'Currency') {
              if (!(e.keyCode < 48 || e.keyCode > 57)) {
                let start = e.target.selectionStart;
                let end = e.target.selectionEnd;
                let value = e.target.value.substring(0, start) + String.fromCharCode(e.keyCode) + e.target.value.substring(end);
                _.set(this, e.target.name, this.convertToCurrency(value));
              }
            } else if (field === 'Number' || field === 'Phone') {
              if (!(e.keyCode < 48 || e.keyCode > 57)) {
                let start = e.target.selectionStart;
                let end = e.target.selectionEnd;
                _.set(this, e.target.name, e.target.value.substring(0, start) + String.fromCharCode(e.keyCode) + e.target.value.substring(end));
                this.$nextTick(() => {
                  e.target.setSelectionRange(start + 1, start + 1);
                });
              }
            } else {
              let newCharUpper = field === 'Email' ? _.toLower(String.fromCharCode(e.keyCode)) : _.toUpper(String.fromCharCode(e.keyCode));
              let start = e.target.selectionStart;
              let end = e.target.selectionEnd;
              _.set(this, e.target.name, e.target.value.substring(0, start) + newCharUpper + e.target.value.substring(end));
              this.$nextTick(() => {
                e.target.setSelectionRange(start + 1, start + 1);
              });
            }
          }
        });
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
          //this is because keypress is not recognized on mobile
          fieldElement.change((e) => {
            e.preventDefault();
            if (field !== 'Radio') {
              if (field === 'Currency') {
                // if (!(e.keyCode < 48 || e.keyCode > 57)) {
                // 	let start = e.target.selectionStart;
                // 	let end = e.target.selectionEnd;
                // 	let value = e.target.value.substring(0, start) + String.fromCharCode(e.keyCode) + e.target.value.substring(end);
                // 	_.set(this, e.target.name, this.convertToCurrency(value));
                // }
              } else if (field === 'Number' || field === 'Phone') {
                // if (!(e.keyCode < 48 || e.keyCode > 57)) {
                // 	let start = e.target.selectionStart;
                // 	let end = e.target.selectionEnd;
                // 	_.set(this, e.target.name, e.target.value.substring(0, start) + String.fromCharCode(e.keyCode) + e.target.value.substring(end));
                // this.$nextTick(() => {
                // 	e.target.setSelectionRange(start + 1, start + 1);
                // });
                // }
              } else {
                _.set(this, e.target.name, field === 'Email' ? _.toLower(e.target.value) : _.toUpper(e.target.value));
              }
            }
          });
        }
      });
    },
    formatAppIdConfirmation: function (e) {
      let newCharUpper = _.toUpper(String.fromCharCode(e.keyCode));
      let start = e.target.selectionStart;
      let end = e.target.selectionEnd;
      _.set(this, e.target.name, e.target.value.substring(0, start) + newCharUpper + e.target.value.substring(end));
      this.$nextTick(() => {
        e.target.setSelectionRange(start + 1, start + 1);
      });
    },
    formatAppIdConfirmationMobile: function (e) {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        //this is because keypress is not recognized on mobile
        _.set(this, e.target.name, _.toUpper(e.target.value));
      }
    },
    prettyPrint: function (value, type) {
      if (type === 'Date') {
        if (_.includes(value, 'T')) {
          return moment(value).format('MMMM Do YYYY h:mm A');
        } else if (!value || value === '') {
          return '';
        } else {
          return moment(value, "YYYY-MM-DD").format('DD-MM-YYYY');
        }
      } else if (type === 'Epoch') {
        return moment(value, 'X').format('MMMM Do YYYY h:mm A');
      } else if (type === 'Currency' && value && value !== '') {
        value = value.toString().replace(/,/g, '');
        let lastThree = value.substring(value.length - 3);
        let otherNumbers = value.substring(0, value.length - 3);

        if (otherNumbers !== '') {
          lastThree = ',' + lastThree;
        }

        return otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
      } else if (type === 'Status') {
        return _.result(_.find(this.getStatuses, (status) => {
          return status.id === value;
        }), 'name') || '';
      } else if (type === 'Product') {
        return _.result(_.find(this.getProducts, (product) => {
          return product.id === value;
        }), 'name') || '';
      } else if (type === 'User') {
        let user = _.find(this.getUsers, (user) => {
          return user.username === value;
        });
        if (user) {
          return _.upperCase(user.firstName + ' ' + user.lastName);
        } else {
          return value;
        }
      } else if (type === 'Array') {
        return _.join(value, ', ');
      } else if (type === 'yesLGCode') {
        return _.result(_.find(this.getBranches, (branch) => {
          return branch.number === value;
        }), 'yesLGCode')
      } else if (type === 'yesLCCode' || type === 'yesSourceCode') {
        const yesCodeSetting = _.find(this.getSettings, ['name', type]);
        if (yesCodeSetting && yesCodeSetting.value) {
          return yesCodeSetting.value;
        } else {
          return '';
        }
      } else if (type === 'yesBranchCode') {
        let yesBranchCodeSetting = yesBranchCodesList[value];
        return yesBranchCodeSetting ? _.padStart(yesBranchCodeSetting.yesBranchCode, 4, '0') : '';
      }
    },
    getUserName: function (user) {
      if (user) {
        let name = _.capitalize(user.firstName);

        if (user.lastName) {
          name += " " + _.capitalize(user.lastName);
        }
        return name;
      } else {
        return '';
      }
    },
    getFormDocuments(documents) {
      this.formDocuments = [];
      _.forEach(documents, (document) => {
        Storage.get(this.formDetails.id + '/' + document, {
          level: 'public'
        }).then((url) => {
          // if (documentType === 'application/pdf') {
          // 	this.formDetails.documents.append('<a href="' + url + '" target="_blank">' +
          // 		'<i class="far fa-file-pdf fa-2x m-r-xs"></i>' +
          // 		'<span>' + url + '</span>' +
          // 		'</a>');
          // } else if (documentType === 'image/jpeg' || documentType === 'image/png') {
          // 	this.formDetails.documents.append('<a href="' + url + '" target="_blank">' +
          // 		'<img id="image' + index + '" style="width:60px; height:60px" src="' + url + '"/>' +
          // 		'</a>');
          // 	$("#image" + index).ezPlus({
          // 		responsive: true,
          // 		scrollZoom: true
          // 	});
          // }
          this.formDocuments.push("<a href='" + url + "' target='_blank'><i class='far fa-file-alt m-r-xs'></i><span>" + document + "</span></a>");
        });
      })
    },
    convertToCurrency: function (value) {
      value = value.toString().replace(/,/g, "");

      let lastThree = value.substring(value.length - 3);
      let otherNumbers = value.substring(0, value.length - 3);

      if (otherNumbers !== "") {
        lastThree = "," + lastThree;
      }

      return otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
    },
    updateSelectedForm: function () {
      $('#updateSelectedForm').attr('disabled', true);
      this.$validator.validateAll()
          .then((result) => {
            if (result) {
              this.validationSuccessful();
            } else {
              $('#updateSelectedForm').attr('disabled', false);
              let firstErrorInput = $('input.error:first')[0] || $('.multiselect.error:first') [0];
              if (firstErrorInput) {
                firstErrorInput.scrollIntoView({behavior: 'instant'});
                firstErrorInput.focus();
              }
              if (this.errors.items && this.errors.items.length > 1) {
                this.setMessage('v-notify-error', "Oops!", "Please correct the " + this.errors.items.length + " errors on the form.");
              } else {
                let errorInputName = firstErrorInput.dataset.vvAs || firstErrorInput.name || 'form';
                if (errorInputName && errorInputName !== '') {
                  errorInputName = _.upperCase(_.replace(errorInputName, "updateDetails.", ""));
                  this.setMessage('v-notify-error', "So Close!", "There is an issue with the " + errorInputName + " field.");
                } else {
                  this.setMessage('v-notify-error', "Sorry!", "Errors in the form!");
                }
              }
            }
          })
          .catch(() => {
            $('#updateSelectedForm').attr('disabled', false);
            let firstErrorInput = $('input.error:first')[0] || $('.multiselect.error:first') [0];
            if (firstErrorInput) {
              firstErrorInput.scrollIntoView({behavior: 'instant'});
              firstErrorInput.focus();
            }
            if (this.errors.items && this.errors.items.length > 1) {
              this.setMessage('v-notify-error', "Oops!", "Please correct the " + this.errors.items.length + " errors on the form.");
            } else {
              let errorInputName = firstErrorInput.dataset.vvAs || firstErrorInput.name || 'form';
              if (errorInputName && errorInputName !== '') {
                errorInputName = _.upperCase(_.replace(errorInputName, "updateDetails.", ""));
                this.setMessage('v-notify-error', "So Close!", "There is an issue with the " + errorInputName + " field.");
              } else {
                this.setMessage('v-notify-error', "Sorry!", "Errors in the form!");
              }
            }
          });
    },
    async validationSuccessful() {
      const currentTimeEpoch = moment().format('X');
      let updateButton = $('#updateSelectedForm');
      try {
        if (this.updateDetails.status && this.updateDetails.status.name !== 'ALREADY APPLIED' && this.updateDetails.previouslyAppliedDate && this.updateDetails.previouslyAppliedDate !== '') {
          updateButton.attr('disabled', false);
          this.setMessage('v-notify-error', "Sorry!", 'Previously Applied Date must be removed if status is NOT "ALREADY APPLIED"');
          return;
        }
        if (this.updateDetails.status && _.includes(["REJECTED", "ALREADY APPLIED", "SUBMITTED", "AIP - INCOMPLETE"], this.updateDetails.status.name)
            && this.updateDetails.referenceNumber && this.updateDetails.referenceNumber !== '') {
          updateButton.attr('disabled', false);
          this.setMessage('v-notify-error', "Sorry!", 'Reference # must be removed if status is not "APPROVED"');
          return;
        }
        if (!this.editJustification && this.updateDetails.statusJustifications && this.updateDetails.statusJustifications.length > 0) {
          updateButton.attr('disabled', false);
          this.setMessage('v-notify-error', "So Close!", 'Status reasons must be removed if selected status does not require it.');
          return;
        }
        if (this.editJustification && (!this.updateDetails.statusJustifications || this.updateDetails.statusJustifications.length < 1)) {
          updateButton.attr('disabled', false);
          this.setMessage('v-notify-error', "Oops!", 'The ' + this.updateDetails.status.name + ' status requires a justification!');
          return;
        }
        if (this.isEditable && this.jobType === 'OutCaller' && (!this.updateDetails.residenceType || this.updateDetails.residenceType === '')) {
          updateButton.attr('disabled', false);
          this.setMessage('v-notify-error', "Try Again!", 'The resident type field is required!');
          return;
        }
        if (this.isEditable && this.jobType === 'OutCaller' && (!this.updateDetails.education || this.updateDetails.education === '')) {
          updateButton.attr('disabled', false);
          this.setMessage('v-notify-error', "Ouch!", 'The education field is required!');
          return;
        }
        if (this.isEditable && this.jobType === 'OutCaller' && (!this.updateDetails.cardMailingAddress || this.updateDetails.cardMailingAddress === '')) {
          updateButton.attr('disabled', false);
          this.setMessage('v-notify-error', "Welp!", 'The send card to field is required!');
          return;
        }
        if (this.updateDetails.statusJustifications && _.includes(this.updateDetails.statusJustifications, "AIP - DECLINED") && (!this.updateDetails.bankResultDate || this.updateDetails.bankResultDate === '')) {
          updateButton.attr('disabled', false);
          this.setMessage('v-notify-error', "Oh No!", 'If reason is AIP - DECLINED then Bank Result Date must be entered.');
          return;
        }
        if (this.updateDetails.status && (this.updateDetails.status.name === "APPROVED" || this.updateDetails.status.name === 'LINK SENT') && this.updateDetails.referenceNumber && this.updateDetails.referenceNumber !== '') {
          const {
            data: {
              formsByReferenceNumber: {items}
            }
          } = await API.graphql(graphqlOperation(customQueries.countFormsByReferenceNumberMinimal, {
            referenceNumber: this.updateDetails.referenceNumber,
            limit: 999
          }));
          if (items.length > 1 || (items.length === 1 && items[0].id !== this.formDetails.id)) {
            updateButton.attr('disabled', false);
            this.setMessage('v-notify-error', "Oops!", 'The provided reference number already exists in the system!"');
            return;
          }
        }
        if (this.updateDetails.status && _.includes(["SUBMITTED", "AIP - INCOMPLETE", "APPROVED", 'LINK SENT', 'ALREADY APPLIED', 'REJECTED'], this.updateDetails.status.name) && this.updateDetails.pancard && this.updateDetails.pancard !== '') {
          const duplicatePancardSetting = _.find(this.getSettings, ['name', 'allowDuplicatePancard']);
          if (duplicatePancardSetting && duplicatePancardSetting.value === "false") {
            const {
              data: {
                formsByPancardProduct: {items}
              }
            } = await API.graphql(graphqlOperation(customQueries.countFormsByPancardProductMinimal, {
              pancard: _.toUpper(this.updateDetails.pancard),
              product: {
                eq: this.formDetails.product
              },
              limit: 999
            }));
            if (items.length > 1 || (items.length === 1 && items[0].id !== this.formDetails.id)) {
              updateButton.attr('disabled', false);
              let existingForm = items.length > 1 ? _.filter(items, (form) => {
                return form.id !== this.formDetails.id;
              })[0] : items[0];
              let existingFormStatusId = existingForm.status;
              let existingFormStatus = _.result(_.find(this.getStatuses, {id: existingFormStatusId}), 'name');
              if (existingFormStatusId === '1fdca13d-adf6-411a-a002-a33e228d22df' || existingFormStatusId === '7795614b-720c-4581-9309-4f569895ac8d') {
                this.setMessage('v-notify-error', "Duplicate Pancard In System!", 'Previously: ' + existingFormStatus + ' @ ' + moment(existingForm.createdAtEpoch, 'X').format('hh:mm a, DD-MM-YYYY'));
              } else if (existingFormStatusId === '60bf9d14-ea35-44a7-88eb-30ff2eaa1712') {
                this.setMessage('v-notify-error', "Duplicate Pancard In System!", 'Previously: ' + existingFormStatus + ' on ' + moment(existingForm.previouslyAppliedDate, 'YYYY-MM-DD').format('DD-MM-YYYY'));
              } else {
                this.setMessage('v-notify-error', "Duplicate Pancard In System!", 'Previously: ' + existingFormStatus + ' @ ' + moment(existingForm.bankVerificationEpoch, 'X').format('hh:mm a, DD-MM-YYYY'));
              }
              return;
            }
          }
        }

        let updateForm = _.omit(this.updateDetails, ['lead', 'logs', 'version', '__typename', 'updatedAt', 'supervisor', 'branchManager', 'owner', 'product']);
        updateForm.id = this.formDetails.id;

        updateForm.status = (this.updateDetails.status && this.updateDetails.status !== '') ? this.updateDetails.status.id : '';

        if (updateForm.previouslyAppliedDate && updateForm.previouslyAppliedDate !== '') {
          //RBL date: dd-mm-yyyy, amazon date: ISO standard yyyy-mm-dd
          let dateChunks = this.updateDetails.previouslyAppliedDate.split('-');
          updateForm.previouslyAppliedDate = dateChunks[2] + '-' + dateChunks[1] + '-' + dateChunks[0];
        }
        if (this.updateDetails.dateOfBirth && this.updateDetails.dateOfBirth !== '') {
          updateForm.age = this.calcAge;
          //RBL date: dd-mm-yyyy, amazon date: ISO standard yyyy-mm-dd
          let dateChunks = this.updateDetails.dateOfBirth.split('-');
          updateForm.dateOfBirth = dateChunks[2] + '-' + dateChunks[1] + '-' + dateChunks[0];
        }
        if (updateForm.bankReceivedDate && updateForm.bankReceivedDate !== '') {
          //RBL date: dd-mm-yyyy, amazon date: ISO standard yyyy-mm-dd
          let dateChunks = this.updateDetails.bankReceivedDate.split('-');
          updateForm.bankReceivedDate = dateChunks[2] + '-' + dateChunks[1] + '-' + dateChunks[0];
          updateForm.bankReceivedEpoch = moment(updateForm.bankReceivedDate, 'YYYY-MM-DD').startOf('day').format('X');
        }
        if (updateForm.bankResultDate && updateForm.bankResultDate !== '') {
          //RBL date: dd-mm-yyyy, amazon date: ISO standard yyyy-mm-dd
          let dateChunks = this.updateDetails.bankResultDate.split('-');
          updateForm.bankResultDate = dateChunks[2] + '-' + dateChunks[1] + '-' + dateChunks[0];
          updateForm.bankResultEpoch = moment(updateForm.bankResultDate, 'YYYY-MM-DD').startOf('day').format('X');
        }

        if (this.updateDetails.status.name === 'ALREADY APPLIED') {
          let ninetyDayCalc = moment(updateForm.previouslyAppliedDate, 'YYYY-MM-DD').add(90, 'days');
          updateForm.dateNinetyDays = ninetyDayCalc.format('YYYY') + '-' + ninetyDayCalc.format('MM') + '-' + ninetyDayCalc.format('DD');
          updateForm.dateNinetyDaysEpoch = ninetyDayCalc.startOf('day').format('X');
        }

        if (_.includes(['APPROVED', 'ALREADY APPLIED', 'REJECTED', 'ORDER CLOSED', 'LINK SENT'], this.updateDetails.status.name) && (!updateForm.bankVerificationDate || updateForm.bankVerificationDate === '')) {
          if (updateForm.status !== this.formDetails.status) {
            updateForm.bankVerificationDate = moment(currentTimeEpoch, 'X').toISOString();
            updateForm.bankVerificationEpoch = currentTimeEpoch;
            if (this.updateDetails.status.name !== 'ALREADY APPLIED') {
              let ninetyDayCalc = moment(currentTimeEpoch, 'X').add(90, 'days');
              updateForm.dateNinetyDays = ninetyDayCalc.format('YYYY') + '-' + ninetyDayCalc.format('MM') + '-' + ninetyDayCalc.format('DD');
              updateForm.dateNinetyDaysEpoch = ninetyDayCalc.startOf('day').format('X');
            }
          }
        }

        _(formFields)
            .pickBy(field => (field === 'Currency' || field === 'Number'))
            .forOwn((field, fieldKey) => {
              if (updateForm[fieldKey] && updateForm[fieldKey] !== '') {
                updateForm[fieldKey] = _.parseInt(_.replace(updateForm[fieldKey], /,/g, ""));
                if (_.isNaN(updateForm[fieldKey])) {
                  updateForm[fieldKey] = null;
                }
              } else {
                updateForm[fieldKey] = null;
              }
            });
        _(formFields)
            .pickBy(field => (field === 'String' || field === 'Date' || field === 'Radio' || field === 'Email'))
            .forOwn((field, fieldKey) => {
              if (updateForm[fieldKey] && updateForm[fieldKey] !== '') {
                updateForm[fieldKey] = _.trim(updateForm[fieldKey]);
                if (field === 'Email') {
                  updateForm[fieldKey] = _.toLower(updateForm[fieldKey]);
                } else if (field === 'String' && fieldKey !== 'owner' && fieldKey !== 'supervisor' && fieldKey !== 'branchManager' && fieldKey !== 'status') {
                  updateForm[fieldKey] = _.toUpper(updateForm[fieldKey]);
                }
              } else {
                updateForm[fieldKey] = null;
              }
            });

        if (updateForm.status !== this.formDetails.status) {
          if (this.updateDetails.status.name === 'APPROVED' || this.updateDetails.status.name === 'LINK SENT') {
            let tse = _.find(this.getUsers, {username: this.formDetails.owner}) || {};
            let approvedEmailData = {
              status: this.updateDetails.status.name,
              bankVerificationEpoch: updateForm.bankVerificationEpoch,
              verifiedIncomeProgram: updateForm.verifiedIncomeProgram ? 'YES' : 'NO',
              customerName: updateForm.firstName + ' ' + updateForm.lastName,
              pancard: updateForm.pancard,
              recipientName: tse.firstName,
              residentCity: updateForm.city,
              product: this.prettyPrint(this.formDetails.product, 'Product'),
              remarks: updateForm.remarks,
              branch: _.result(_.find(this.getBranches, (branch) => {
                return branch.number === updateForm.branchId;
              }), 'name'),
              owner: tse.firstName,
              referenceNumber: updateForm.referenceNumber
            };
            let toEmailAddresses = [];
            let ccEmailAddresses = [];
            let bccEmailAddresses = [];

            if (tse.email && tse.email !== '') {
              toEmailAddresses.push(tse.email);
            }
            let supervisor = _.find(this.getUsers, {id: tse.supervisor}) || {};
            if (supervisor.email && supervisor.email !== '' && supervisor.firstName && supervisor.firstName !== '') {
              approvedEmailData.supervisor = supervisor.firstName;
              if (toEmailAddresses.length < 1) {
                toEmailAddresses.push(supervisor.email);
              } else {
                bccEmailAddresses.push(supervisor.email);
              }
            }
            let manager = _.find(this.getUsers, {id: supervisor.supervisor}) || {};
            if (manager.email && manager.email !== '') {
              if (toEmailAddresses.length < 1) {
                toEmailAddresses.push(manager.email);
              } else {
                bccEmailAddresses.push(manager.email);
              }
            }
            bccEmailAddresses.push("shahnuupur@gmail.com");
            bccEmailAddresses.push("birenpopat@gmail.com");
            this.sendUpdateEmail({
                  ...approvedEmailData
                },
                toEmailAddresses,
                ccEmailAddresses,
                bccEmailAddresses
            );
          } else if (_.includes(["ALREADY APPLIED", "REJECTED"], this.updateDetails.status.name)) {
            let tse = _.find(this.getUsers, {username: this.formDetails.owner});
            if (tse.email && tse.email !== '') {
              this.sendUpdateEmail({
                    status: this.updateDetails.status.name,
                    bankVerificationEpoch: updateForm.bankVerificationEpoch,
                    product: this.prettyPrint(this.formDetails.product, 'Product'),
                    customerName: updateForm.firstName + ' ' + updateForm.lastName,
                    recipientName: tse.firstName,
                  },
                  [tse.email]
              );
            }
          }
        }

        updateForm = _.omit(updateForm, ['logs', 'version', '__typename', 'updatedAt', 'owner', 'supervisor', 'branchManager']);
        updateForm.updatedBy = this.getLoggedInUser.username;
        updateForm.updatedAtEpoch = currentTimeEpoch;
        updateForm.expectedVersion = this.formDetails.version;

        const {data} = await API.graphql(graphqlOperation(mutations.updateForm, {
          input: _.omitBy(updateForm, (field, fieldKey) => {
            if (_.includes(this.updateDetails.status.editable, this.jobType)) {
              return field === '';
            } else {
              return (!_.includes(_.keys(simplifiedFormFields), fieldKey) && fieldKey !== 'expectedVersion') || field === '';
            }
          })
        }));
        let updatedForm = data.updateForm;

        let oldForms = _.cloneDeep(this.getForms);
        let formIndex = _.findIndex(oldForms, (oldForm) => {
          return oldForm.id === updatedForm.id;
        });
        if (formIndex !== -1) {
          oldForms[formIndex] = updatedForm;
          this.UPDATE_FORMS(_.uniqBy(oldForms, 'id'));
          this.UPDATE_UPDATE_SUBSCRIPTION_FORM(updatedForm);
        }

        updateButton.attr('disabled', false);
        this.setMessage('v-notify-success', "Nice!", "The form was updated successfully!");
        $("#formDetailsModal").modal('hide');
      } catch (e) {
        updateButton.attr('disabled', false);
        this.setMessage('v-notify-error', "Error!", e);
      }
    },
    generateUniqueLink: async function () {
      this.formDetails.documentSubmissionLink = ' ';

      try {
        const response = await API.get("SalesVoltPrivate", "/documentUrl", {
          headers: {
            Authorization: `${(await Auth.currentSession()).getIdToken().getJwtToken()}`
          },
          queryStringParameters: {
            formId: this.formDetails.id
          }
        });
        if (response) {
          let updatedForm = response;

          let oldForms = _.cloneDeep(this.getForms);
          let formIndex = _.findIndex(oldForms, (oldForm) => {
            return oldForm.id === updatedForm.id;
          });
          if (formIndex !== -1) {
            oldForms[formIndex] = updatedForm;
            this.UPDATE_FORMS(_.uniqBy(oldForms, 'id'));
            this.UPDATE_UPDATE_SUBSCRIPTION_FORM(updatedForm);
          }
        } else {
          this.formDetails.documentSubmissionLink = '';
          this.setMessage('v-notify-error', "Error!", e);
        }
      } catch (e) {
        this.setMessage('v-notify-error', "Error!", e);
      }
    },
    async sendUpdateEmail(emailData, toAddresses = [], ccEmailAddresses = [], bccEmailAddresses = []) {
      const emailTemplateSetting = _.find(this.getSettings, ['name', 'emailTemplate']);
      if (!emailData || !toAddresses || toAddresses.length < 1 || !emailTemplateSetting || !emailTemplateSetting.value) {
        return;
      }
      try {
        let {
          error
        } = await API.put("SalesVoltPrivate", '/emails', {
          body: {
            emailData: emailData,
            toAddresses: toAddresses,
            ccEmailAddresses: ccEmailAddresses,
            bccEmailAddresses: bccEmailAddresses,
            template: emailTemplateSetting.value
          },
          headers: {
            Authorization: `${(await Auth.currentSession()).getIdToken().getJwtToken()}`
          }
        });
        if (error) {
          this.setMessage('v-notify-error', "Something went wrong!", 'Unable to send update email to tse. ' + error);
        }
      } catch (err) {
        this.setMessage('v-notify-error', "Something went wrong!", 'Unable to send update email to tse. ' + err);
      }
    },
    async updateCustomerResponse(response) {
      const currentTimeEpoch = moment().format('X');
      let interestedButton = $('#customerInterestedButton');
      let notInterestedButton = $('#customerNotInterestedButton');
      interestedButton.attr('disabled', true);
      notInterestedButton.attr('disabled', true);
      let input = {
        id: this.formDetails.id,
        previouslyAppliedDate: null,
        bankReceivedDate: null,
        bankReceivedEpoch: null,
        bankResultDate: null,
        bankResultEpoch: null,
        bankVerificationDate: null,
        bankVerificationEpoch: null,
        dateNinetyDays: null,
        dateNinetyDaysEpoch: null,
        referenceNumber: null,
        remarks: null,
        status: response === 'Yes' ? '2d986013-050e-4e8b-8b40-1b959be382c4' : '64d62e04-7c87-436a-9c60-8dde4ee21ceb', //TODO: FIX ---- Hardcoded ids = Bad idea
        statusJustifications: null,
        updatedBy: this.getLoggedInUser.username,
        updatedAtEpoch: currentTimeEpoch,
        expectedVersion: this.formDetails.version
      };

      if (response === 'No') {
        let ninetyDayCalc = moment(currentTimeEpoch, 'X').add(90, 'days');
        input.dateNinetyDays = ninetyDayCalc.format('YYYY') + '-' + ninetyDayCalc.format('MM') + '-' + ninetyDayCalc.format('DD');
        input.dateNinetyDaysEpoch = ninetyDayCalc.format('X');
      }

      try {
        const {
          data: {
            updateForm
          }
        } = await API.graphql(graphqlOperation(mutations.updateForm, {
          input: input
        }));

        interestedButton.attr('disabled', false);
        notInterestedButton.attr('disabled', false);
        $("#formDetailsModal").modal('hide');
        this.UPDATE_DELETE_SUBSCRIPTION_FORM(updateForm);
        this.setMessage('v-notify-success', "Nice!", "The form was updated successfully!");
      } catch (err) {
        this.setMessage('v-notify-error', "Something went wrong!", 'Unable to update form. ' + err);
        interestedButton.attr('disabled', false);
        notInterestedButton.attr('disabled', false);
        $("#formDetailsModal").modal('hide');
      }
    },
    async updateDocumentsReceived() {
      const currentTimeEpoch = moment().format('X');
      let documentsButton = $('#documentsReceivedButton');
      let notInterestedButton = $('#customerNotInterestedButton');
      documentsButton.attr('disabled', true);
      notInterestedButton.attr('disabled', true);
      let input = {
        id: this.formDetails.id,
        previouslyAppliedDate: null,
        bankReceivedDate: null,
        bankReceivedEpoch: null,
        bankResultDate: null,
        bankResultEpoch: null,
        remarks: null,
        status: 'a9525cb3-d038-4eae-8236-27e2e056597b', //NOTE: Hardcoded ids = Bad idea
        statusJustifications: null,
        updatedBy: this.getLoggedInUser.username,
        updatedAtEpoch: currentTimeEpoch,
        expectedVersion: this.formDetails.version
      };

      try {
        const {
          data: {
            updateForm
          }
        } = await API.graphql(graphqlOperation(mutations.updateForm, {
          input: input
        }));

        documentsButton.attr('disabled', false);
        notInterestedButton.attr('disabled', false);
        $("#formDetailsModal").modal('hide');
        this.UPDATE_DELETE_SUBSCRIPTION_FORM(updateForm);
        this.setMessage('v-notify-success', "Nice!", "The form was updated successfully!");
      } catch (err) {
        this.setMessage('v-notify-error', "Something went wrong!", 'Unable to update form. ' + err);
        documentsButton.attr('disabled', false);
        notInterestedButton.attr('disabled', false);
        $("#formDetailsModal").modal('hide');
      }
    },
    deleteSelectedForm: function () {
      $('#deleteSelectedForm').attr('disabled', true);
      this.setMessage('confirm', 'Are you sure?', 'You are about to permanently delete this form!').then((data) => {
        if (data && data.data) {
          this.deleteFormConfirmed();
        } else {
          $('#deleteSelectedForm').attr('disabled', false);
        }
      });
    },
    async deleteFormConfirmed() {
      try {
        const {
          data: {
            deleteForm
          }
        } = await API.graphql(graphqlOperation(mutations.deleteForm, {
          input: {
            id: this.formDetails.id,
            expectedVersion: this.formDetails.version
          }
        }));

        $('#deleteSelectedForm').attr('disabled', false);
        $("#formDetailsModal").modal('hide');
        this.UPDATE_DELETE_SUBSCRIPTION_FORM(deleteForm);
        this.setMessage('v-notify-success', "Nice!", "The form was deleted successfully!");
      } catch (err) {
        $('#deleteSelectedForm').attr('disabled', false);
        this.setMessage('v-notify-error', 'Sorry!', err);
      }
    },
    updateFormOwner: function () {
      $('#updateFormOwner').attr('disabled', true);
      this.setMessage('confirm', 'Are you sure?', 'You are about to permanently change the form owner!').then((data) => {
        if (data && data.data) {
          this.formOwnerChangeConfirmed();
        } else {
          $('#updateFormOwner').attr('disabled', false);
        }
      });
    },
    async formOwnerChangeConfirmed() {
      try {
        const currentTimeEpoch = moment().format('X');
        let updateForm = {
          id: this.formDetails.id,
          owner: _.result(this.updateDetails.owner, 'username'),
          branchId: this.updateDetails.owner.branchId[0],
          updatedBy: this.getLoggedInUser.username,
          updatedAtEpoch: currentTimeEpoch,
          expectedVersion: this.formDetails.version
        };
        let supervisor = _.find(this.getUsers, (user) => {
          return user.id === this.updateDetails.owner.supervisor;
        });
        if (supervisor) {
          updateForm.supervisor = supervisor.username;

          let manager = _.find(this.getUsers, (user) => {
            return user.id === supervisor.supervisor;
          });

          if (manager) {
            updateForm.branchManager = manager.username;
          }
        }
        const {data} = await API.graphql(graphqlOperation(mutations.updateForm, {
          input: updateForm
        }));
        let updatedForm = data.updateForm;

        let oldForms = _.cloneDeep(this.getForms);
        let formIndex = _.findIndex(oldForms, (oldForm) => {
          return oldForm.id === updatedForm.id;
        });
        if (formIndex !== -1) {
          oldForms[formIndex] = updatedForm;
          this.UPDATE_FORMS(_.uniqBy(oldForms, 'id'));
          this.UPDATE_UPDATE_SUBSCRIPTION_FORM(updatedForm);
        }
        $('#updateFormOwner').attr('disabled', false);
        $("#formDetailsModal").modal('hide');
      } catch (err) {
        $('#updateFormOwner').attr('disabled', false);
        this.setMessage('v-notify-error', 'Sorry!', err);
      }
    }
  }
}
</script>

<style scoped>

</style>
